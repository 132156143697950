import React from 'react';
import "./Step1.css"
import HeaderDashboard from '../../../pages/dashboard/header'
import { useParams, useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';

const Step1 = (props) => {
    const history = useHistory();
    const id = useParams()
    const [validates, setValidates] = React.useState([]);
    const [input, setInput] = React.useState({
        title: '',
        describe: ''
    });
    const handleChange = (value, type) => {
        setInput(prev => {
            return {
                ...prev,
                [type]: value
            }
        });
    }
    props.updateTitle(input.title)
    props.updateDescribe(input.describe)
    const handleValidate = () => {
        setValidates([]);
        let isValid = true;
        if (input.title === '') {
            setValidates(prev => ([...prev, { title: ['Title is required'] }]));
            isValid = false;
        }
        if (input.describe === '') {
            setValidates(prev => ([...prev, { describe: ['Description is required'] }]));
            isValid = false;
        }
        return isValid;
    }
    const getErrorMessage = type => {
        const currentError = validates.find(validate => validate[type]);
        if (typeof currentError === 'undefined') {
            return [];
        }
        return currentError[type];
    }
    const handleNext = () => {
        if (!handleValidate()) {
            return;
        }
        document.getElementById('nextstep').click();
    }
    return (
        <>
            <HeaderDashboard />
            <div className='container-fluid'>
                <div className="create-form col-lg-4 col-md-8 col-sm-12">
                    <h3>Project's info</h3>
                    <form>
                        <div className="form-group" style={{ marginTop: '20px' }}>
                            <label>Title</label>
                            <input type="text"
                                className="form-control"
                                placeholder="Project's Title"
                                value={input.title}
                                onChange={(e) => { handleChange(e.target.value, 'title') }}
                            />
                        </div>
                        <Typography variant="body" gutterBottom component="div" sx={{ paddingTop: '15px', color: 'red', margin: 0 }}>
                            {
                                getErrorMessage('title').map(message => {
                                    return message
                                })
                            }
                        </Typography>
                        <div className="form-group" style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <label>Description</label>
                            <textarea className="form-control"
                                placeholder="Project's Description"
                                value={input.describe}
                                onChange={(e) => { handleChange(e.target.value, 'describe') }}
                            ></textarea>
                        </div>
                        <Typography variant="body" gutterBottom component="div" sx={{ color: 'red', margin: 0 }}>
                            {
                                getErrorMessage('describe').map(message => {
                                    return message
                                })
                            }
                        </Typography>
                        <div className="form-group">
                            {/* <label>Author</label>
                            <input type="text" className="form-control" placeholder="Author" defaultValue={JSON.parse(localStorage.getItem('email'))} /> */}
                        </div>
                    </form>
                    <div className='step-button'>
                        <button style={{
                            backgroundColor: '#222222', border: '#222222'
                        }} onClick={() => { history.push(`/user=${id.user_id}`) }} className="btn btn-danger btn-block">Cancel</button>
                        <button id='nextstep' style={{ display: 'none' }} onClick={props.nextStep}></button>
                        <button style={{
                            backgroundColor: '#222222', border: '#222222'
                        }} type="submit" className="btn btn-primary btn-block"
                            onClick={() => handleNext()}
                        >Next</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Step1