import Http from "./Http";
import HttpAuth from "./HttpAuth";
//auth
export const loginAPI = (config) => {
    return HttpAuth.post('/v1/auth/login', config);
}
export const registerAPI = (config) => {
    return HttpAuth.post('/v1/auth/register', config)
}
export const logoutAPI = (config) => {
    return HttpAuth.get('/v1/auth/logout', config)
}

export const requestResetPasswordAPI = (config) => {
    return HttpAuth.post('/v1/auth/reset-password', config)
}
export const resetPasswordApi = (token, data) => {
    return HttpAuth.put(`/v1/auth/reset-password/${token}`, data)
}
//
//user
export const getUserInfoAPI = (id, config) => {
    return Http.get(`/v1/user/${id}`, config)
}
export const updateUserInfoAPI = (id, data) => {
    return Http.put(`/v1/user/${id}`, data)
}
//
//project
export const loadProjectAPI = (id, config) => {
    return Http.get(`/v1/project/${id}`, config);
}
export const uploadProjectAPI = (data, options) => {
    return Http.post('/v1/project', data, options);
}
export const loadAllProjectAPI = (config, options) => {
    return Http.get(`/v1/project?apikey=${config}`, options)
}
export const updateProjectAPI = (id, config) => {
    return Http.put(`/v1/project/${id}`, config)
}
export const exportProjectUrl = (id, config) => {
    return Http.put(`/v1/project/ ${id}`, config);
}
export const deleteProjectAPI = (id, config) => {
    return Http.delete(`/v1/project/${id}`, config)
}
//
//image
export const uploadEditImageAPI = (data) => {
    return Http.post('/v1/image', data)
}

//
//infospot
export const createLabelAPI = (config, options) => {
    return Http.post('/v1/label', config, options)
}
export const loadLabelAPI = (id, config) => {
    return Http.get(`/v1/label/${id}`, config)
}
export const deleteLabelAPI = (id) => {
    return Http.delete(`/v1/label/${id}`)
}
export const updateLabelAPI = (id, data) => {
    return Http.put(`/v1/label/${id}`, data)
}
export const uploadMediaAPI = (data) => {
    return Http.post(`/v1/label/media`, data)
}
//
//link
export const createLinkAPI = (config) => {
    return Http.post('/v1/link', config)
}
export const loadLinkAPI = (id, config) => {
    return Http.get(`/v1/link/${id}`, config)
}
export const deleteLinkAPI = (id) => {
    return Http.delete(`/v1/link/${id}`)
}
export const updateLinkAPI = (id, data) => {
    return Http.put(`/v1/link/${id}`, data)
}
//
//search
export const searchAPI = (keyword, config) => {
    return Http.get(`/v1/search/${keyword}?user=${config}`)
}