import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Auth from "./auth";
import ApiList from "./components/ApiList";
import ChangePassword from "./components/ChangePassword";
import CreateProject from "./components/CreateProject";
import EditProject from "./components/EditProject/";
import Embed from "./components/Embed";
import Preview from "./components/Preview";
import ProfilePage from "./components/ProfilePage";
import ProtectRoute from "./components/ProtectRoute";
import ResetPassword from "./components/ResetPassword";
import ViewProject from "./components/ViewProject";
import Dashboard from "./pages/dashboard";
const App = () => {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Redirect exact from="/" to="/sign-in" />
          <Route exact path="/sign-in" component={Auth} />
          <Route exact path='/reset-password/:token' component={ResetPassword} />
          <ProtectRoute exact path='/user=:user_id' component={Dashboard} />
          <ProtectRoute exact path='/user=:user_id/create' component={CreateProject} />
          <ProtectRoute exact path='/user=:user_id/project=:prj_id/preview' component={Preview} />
          <ProtectRoute exact path='/user=:user_id/editproject=:prj_id' component={EditProject} />
          <ProtectRoute exact path='/user=:user_id/project=:prj_id' component={ViewProject} />
          <ProtectRoute exact path='/user=:user_id/editprofile' component={ProfilePage} />
          <ProtectRoute exact path='/user=:user_id/changepassword' component={ChangePassword} />
          <ProtectRoute exact path='/user=:user_id/api' component={ApiList} />
          <Route exact path='/embed/project=:prj_id' component={Embed} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
