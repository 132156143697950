import React from "react";
import DashboardMain from "./dashboard-body";
import HeaderDashboard from "./header";
const Dashboard = () => {
    return (
        <div className='main'>
            <HeaderDashboard />
            <DashboardMain />
        </div >
    )
}
export default Dashboard