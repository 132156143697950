import ApiOutlinedIcon from '@mui/icons-material/ApiOutlined';
import CheckIcon from '@mui/icons-material/Check';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MenuIcon from '@mui/icons-material/Menu';
//MUI
import { Button } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from "react";
import { Dropdown } from 'react-bootstrap';
import { Link, useHistory, useParams } from "react-router-dom";
import { getUserInfoAPI, logoutAPI } from "../../../services/Api";
import { BASE_URL } from "../../../services/constants/app";
import "./header.css";


const HeaderDashboard = () => {
    const [user, setUser] = React.useState({});
    const history = useHistory();
    const id = useParams();
    const [isCopied, setIsCopied] = React.useState(false);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    React.useEffect(async () => {
        if (id.user_id === localStorage.getItem('user')) {
            await getUserInfoAPI(id.user_id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accept': 'application/json',
                }
            }).then((res) => {
                setUser(res.data.data)
            })
        }
    }, [])
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static" sx={{ height: "80px", backgroundImage: 'linear-gradient(147deg, #000000 0%, #434343 74%)' }}>
            <Container maxWidth="xl" sx={{ height: "80px" }}>
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, zIndex: "999" }}
                    >
                        <Link className="" to={`/user=${id.user_id}`}><img className="img-fluid logo" alt="" src={`${BASE_URL}/images/logo.png`}></img></Link>
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <MenuItem onClick={handleCloseNavMenu} right="right" onClick={() => { window.open('https://github.com/MHunga/amz_360/blob/1.0.4/README.md') }}>
                                <Typography
                                    textAlign="center">Mobile SDK</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu} right="right" onClick={() => { window.open('http://demego.vn/page/about-us.html') }} >
                                <Typography textAlign="center">Contact</Typography>
                            </MenuItem>
                            <Link to={`/user=${id.user_id}/api`}>
                                <MenuItem onClick={handleCloseNavMenu} right="right"  >
                                    <Typography textAlign="center">Get API </Typography>
                                </MenuItem>
                            </Link>

                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                    >
                        <Link to={`/user=${id.user_id}`}><img className="img-fluid logo" alt="" src={`${BASE_URL}/images/logo.png`}></img></Link>

                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, position: "relative", justifyContent: "right", right: "30%" }}>
                        <Button
                            variant="text"
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            onClick={() => { window.open('https://github.com/MHunga/amz_360/blob/1.0.4/README.md') }}
                        >
                            <p id="basic-button-text" style={{ margin: '0' }}>Mobile SDK</p>
                        </Button>
                        <Button
                            variant="text"
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            onClick={() => { window.open('http://demego.vn/page/about-us.html') }}
                        >
                            <p id="basic-button-text" style={{ margin: '0' }}>Contact</p>
                        </Button>
                        <Link to={`/user=${id.user_id}/api`}>
                            <Button
                                variant="text"
                                id="basic-button"
                                aria-controls="basic-menu"
                                aria-haspopup="true"
                            >
                                <p id="basic-button-text" style={{ margin: '0' }}>Get API</p>
                            </Button>
                        </Link>
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Dropdown>
                            <Dropdown.Toggle variant='dark' id="dropdown-basic" className='profile'>
                                <img className="" src={`${BASE_URL}/images/avatar.png`} alt=""></img>
                                <span style={{ color: 'white' }}>{user.name}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href={`/user=${id.user_id}/editprofile`}><EditOutlinedIcon className='profile-btn' />Edit profile</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href={`/user=${id.user_id}/changepassword`} ><KeyOutlinedIcon className='profile-btn' />Change password</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                    onBlur={() => { setIsCopied(false) }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setIsCopied(!isCopied);
                                        return navigator.clipboard.writeText(`${user.apikey}`)
                                    }}
                                >
                                    {isCopied === false ? <p style={{ margin: 0 }}><ApiOutlinedIcon className='profile-btn' />Get Api Key</p> : <p style={{ margin: 0 }}><ApiOutlinedIcon className='profile-btn' />Copied <CheckIcon /></p>}
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={async () => {
                                    await logoutAPI({
                                        headers: {
                                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                            'Accept': 'application/json',
                                        }
                                    }
                                    ).then(() => {
                                        localStorage.clear();
                                        history.push('/');
                                    })
                                }} ><LogoutOutlinedIcon sx={{ color: 'red' }} className='profile-btn' /><span id='logout'>Log out</span></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar >
    )

}
export default HeaderDashboard;