import LinearProgress from '@mui/material/LinearProgress';
import * as PANOLENS from "panolens";
import React from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import * as THREE from "three";
import { loadLabelAPI, loadLinkAPI, loadProjectAPI } from "../../services/Api";
import { BASE_URL } from '../../services/constants/app';
import { spotIcon } from '../Base64Icon';
import "./Preview.css";

let thumbnails = [];
const Preview = (props) => {
    const history = useHistory();
    const [project, setProject] = React.useState([]);
    const [label, setLabel] = React.useState();
    const [link, setLink] = React.useState();
    const [img, setImg] = React.useState();
    const [viewer, setViewer] = React.useState(null);
    const [pano, setPano] = React.useState(new PANOLENS.ImagePanorama(img));
    const [onProgress, setOnProgress] = React.useState(0);

    let infospot = null;
    const id = useParams();
    //Render view and add panorama
    React.useEffect(() => {
        loadProjectAPI(id.prj_id,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            }).then((res) => {
                setProject(res.data.data);
            })

        const initPanolens = new PANOLENS.Viewer(
            {
                container: document.getElementById('editor-frame'),
                controlBar: true,
                output: 'event',
                controlButtons: ['fullscreen'],
                autoRotate: false,
                autoHideInfospot: true,
            }
        );
        setViewer(initPanolens);
        initPanolens.add(pano);
    }, []);
    React.useEffect(() => {
        thumbnails = [];
        project.map((value) => {
            value.images.map((value, index) => {
                thumbnails.push(
                    {
                        key: value.id,
                        thumbnailUrl: value.thumbnail_url,
                        imageUrl: value.url,
                    }
                );
                if (index === 0) {
                    setImg(value.url);
                    setPano(new PANOLENS.ImagePanorama(value.url));
                }
            });
        })
    }, [project]);

    React.useEffect(() => {
        if (viewer !== null) {
            viewer.remove(viewer.panorama);
            viewer.add(pano);
            viewer.setPanorama(pano);
            pano.addEventListener('progress', function (e) {
                let percent = e.progress.loaded / e.progress.total * 100;
                setOnProgress(percent)
            })
        }

    }, [pano]);
    React.useEffect(async () => {
        if (thumbnails.find(thumbnail => thumbnail.imageUrl === img)) {
            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            }
            await loadLabelAPI(thumbnails.find(thumbnail => thumbnail.imageUrl === img).key, config).then((res) => {
                setLabel(res.data.data.label);

            });
            await loadLinkAPI(thumbnails.find(thumbnail => thumbnail.imageUrl === img).key, config).then((res) => {
                setLink(res.data.data.link)
            });
        }
    }, [img])
    React.useEffect(() => {
        label?.map((value) => {
            const positions = JSON.parse(value.positions);
            if (value.text !== null) {
                infospot = new PANOLENS.Infospot(400, spotIcon.text);
                infospot.addHoverElement(document.querySelector(`#spot-text-ele-${value.id}`));
            }
            if (value.image_url !== null) {
                infospot = new PANOLENS.Infospot(400, spotIcon.image);
                infospot.addHoverElement(document.querySelector(`#spot-img-ele-${value.id}`));
            }
            if (value.video_url !== null) {
                infospot = new PANOLENS.Infospot(400, spotIcon.video);
                infospot.addHoverElement(document.querySelector(`#spot-vid-ele-${value.id}`));
                document.querySelector(`#spot-vid-ele-${value.id}`).removeAttribute('id');
                infospot.addEventListener('hoverenter', function () {
                    let eleArray = document.getElementsByClassName('spot-vid-ele');
                    if (eleArray.length > 2) {
                        eleArray[1].remove()
                    }
                    document.getElementById('editor-frame').click()
                    document.getElementById(`spot-vid-ele-${value.id}`).play()
                })
                infospot.addEventListener('hoverleave', function () {
                    document.getElementById(`spot-vid-ele-${value.id}`).pause()
                })
            }
            infospot.position.set(positions[0], positions[1], positions[2]);
            infospot.uuid = value.id;
            if (thumbnails.find(thumbnail => thumbnail.imageUrl === img).key === value.image_id) {
                pano.add(infospot);
            };

        })
    }, [label])
    React.useEffect(() => {
        if (link !== undefined) {
            link?.map((value, index) => {
                const positions = JSON.parse(value.positions);
                if (thumbnails.find(thumbnail => thumbnail.imageUrl === img).key === value.image_id) {
                    const toPano = new PANOLENS.ImagePanorama(value.url);
                    pano.link(toPano, new THREE.Vector3(positions[0], positions[1], positions[2]), 400, spotIcon.link);
                    pano.visible = true;
                    pano.children.map((item, index) => {
                        if (item.toPanorama !== null) {
                            item.addHoverElement(document.querySelector(`#preview-link-${value.id}`))
                            item.addEventListener('click', function () {
                                pano.addEventListener('leave', function () {
                                    document.getElementById('editor-frame').classList.add('transition-start')
                                })
                                pano.addEventListener('leave-complete', function () {
                                    document.getElementById('editor-frame').classList.add('transition-end')
                                    document.getElementById('editor-frame').classList.remove('transition-start')
                                    document.getElementById('editor-frame').classList.remove('transition-end')
                                })
                            })
                        }
                    })
                    toPano.addEventListener('load', function (e) {
                        setImg(value.url);
                        setPano(new PANOLENS.ImagePanorama(value.url));
                    });
                    viewer.add(pano, toPano);
                    viewer.setPanorama(pano)
                };
            })
        }
    }, [link]);
    // Change img by thumbnails

    const imgClick = imageUrl => {
        setImg(imageUrl);
        setPano(new PANOLENS.ImagePanorama(imageUrl));
    }
    return (
        <>
            {
                label?.map((value) => {
                    return (
                        <>
                            {
                                value.text !== null &&
                                <div id={`spot-text-ele-${value.id}`} className="spot-text-ele" style={{ display: "none", }} >
                                    <div className='text-ele__title'>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: value.title
                                            }}
                                        />
                                    </div>
                                    <div className='text-ele__describe'>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: value.text
                                            }}
                                        />
                                    </div>
                                </div>

                            }
                            {
                                value.image_url !== null &&
                                <div id={`spot-img-ele-${value.id}`} className='spot-img-ele' style={{ display: "none" }} >
                                    <div className='img-ele__title'>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: value.title
                                            }}
                                        />                                    </div>
                                    <div className='img-ele__img'>
                                        <img className='img-fluid'
                                            src={value.image_url}
                                        />
                                    </div>
                                </div>

                            }
                            {
                                value?.video_url !== null &&
                                <video
                                    muted="muted"
                                    className='spot-vid-ele'
                                    style={{ display: 'none' }}
                                    id={`spot-vid-ele-${value.id}`} width="400" height="fit-content" controls="controls" controlsList="nofullscreen nodownload noplaybackrate" disablePictureInPicture="" >
                                    <source src={value.video_url} type="video/mp4" />
                                </video>

                            }

                        </>
                    )
                })
            }
            {
                link?.map((value, index) => {
                    const preview_url = thumbnails.find(thumbnail => thumbnail.imageUrl === value.url)
                    return (
                        <div style={{ display: 'none' }} className='preview-link img-ele__img' id={`preview-link-${value.id}`}>
                            <img className='img-fluid preview-image' src={preview_url.thumbnailUrl}></img>
                        </div>
                    )
                })
            }
            {
                onProgress > 0 && onProgress !== 100 && <LinearProgress sx={{ height: '10px' }} variant="determinate" value={onProgress} />
            }
            <div id='editor-frame'>
                <div className='container-fluid header-tools'>
                    <div className='row' style={{ width: '100%' }}>

                        <div className='col-sm' ><button type="button" className="btn btn-success" style={{
                            backgroundColor: '#222222', border: '#222222'
                        }} onClick={() => { history.goBack() }}>Continue Editing</button></div>
                        <div className='col-sm header-title'><p>{project.map((value) => { return value.title })}</p>
                        </div>
                        <div className='col-sm header-button'>
                            <Link to={`/user=${id.user_id}`} style={{ float: 'right' }}>
                                <button
                                    style={{
                                        backgroundColor: '#222222', border: '#222222'
                                    }}
                                    onClick={() => {
                                        localStorage.removeItem('project_id');
                                    }} type="button" className="btn btn-success">Finish</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='container'>
                    <div className="thumbnail fixed-bottom  col-lg-12 col-md-6 col-sm-6 col-xs-6">
                        <div className="backArrow arrow" >
                            <button
                                className="carousel-control-prev"
                                type="button"
                                onClick={() => {
                                    const currentImage = thumbnails.find(thumbnail => thumbnail.imageUrl === img);
                                    if (typeof currentImage == 'undefined') {
                                        return;
                                    }
                                    const nextImage = thumbnails.find(thumbnail => thumbnail.key === currentImage.key - 1);
                                    if (typeof nextImage == 'undefined') {
                                        imgClick(thumbnails[thumbnails.length - 1].imageUrl);
                                        return;
                                    }
                                    imgClick(nextImage.imageUrl);
                                }}
                            >
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                        </div>
                        {thumbnails.map((item, index) => {
                            return (
                                item.thumbnailUrl === null ?
                                    <img className={`d-block img-fluid thumbnail-image ${item.imageUrl === img ? 'active' : ''} `} onClick={() => imgClick(item.imageUrl)} value={index} id="img" src={`${BASE_URL}/images/default.png`} />
                                    :
                                    <img className={`d-block img-fluid thumbnail-image ${item.imageUrl === img ? 'active' : ''} `} onClick={() => imgClick(item.imageUrl)} value={index} id="img" src={item.thumbnailUrl} />
                            )
                        })}
                        <div className="nextArrow arrow" >
                            <button
                                className="carousel-control-next"
                                type="button"
                                onClick={() => {
                                    const currentImage = thumbnails.find(thumbnail => thumbnail.imageUrl === img);
                                    if (typeof currentImage == 'undefined') {
                                        return;
                                    }
                                    const nextImage = thumbnails.find(thumbnail => thumbnail.key === currentImage.key + 1);
                                    if (typeof nextImage == 'undefined') {
                                        imgClick(thumbnails[0].imageUrl);
                                        return;
                                    }
                                    imgClick(nextImage.imageUrl);
                                }}
                            >
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Preview
////////////////////////////////////////////////////////////////////
//                          _ooOoo_                               //
//                         o8888888o                              //
//                         88" . "88                              //
//                         (| -_- |)                              //
//                         O\  =  /O                              //
//                      ____/`---'\____                           //
//                    .'  \\|     |//  `.                         //
//                   /  \\|||  :  |||//  \                        //
//                  /  _||||| -:- |||||-  \                       //
//                  |   | \\\  -  /// |   |                       //
//                  | \_|  ''\---/''  |   |                       //
//                  \  .-\__  `-`  ___/-. /                       //
//                ___`. .'  /--.--\  `. . ___                     //
//              ."" '<  `.___\_<|>_/___.'  >'"".                  //
//            | | :  `- \`.;`\ _ /`;.`/ - ` : | |                 //
//            \  \ `-.   \_ __\ /__ _/   .-` /  /                 //
//      ========`-.____`-.___\_____/___.-`____.-'========         //
//                           `=---='                              //
//      ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^        //
//             Buddha bless, never BUG, ​​never modify              //
////////////////////////////////////////////////////////////////////
//Đức Phật nơi đây phù hộ code con chạy không Bug. Nam mô a di đà phật