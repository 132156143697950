import React from 'react'
import "./ProfilePage.css"
import { updateUserInfoAPI, getUserInfoAPI } from '../../services/Api';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useHistory, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import HeaderDashboard from '../../pages/dashboard/header/';

const ProfilePage = () => {
    const id = useParams();
    const history = useHistory();
    const [status, setStatus] = React.useState();
    const [error, setError] = React.useState();
    const [user, setUser] = React.useState({});
    const [validates, setValidates] = React.useState([]);
    const [input, setInput] = React.useState({
        name: '',
        phone: ''

    });
    const [isPending, setIsPending] = React.useState(false);
    React.useEffect(async () => {
        await getUserInfoAPI(id.user_id, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Accept': 'application/json',
            }
        }).then((res) => {
            setUser(res.data.data)
        })
    }, []);

    const handleChangeInput = (value, type) => {
        setInput(prev => {
            return {
                ...prev,
                [type]: value
            }
        });
    }
    const handleFormSubmit = async (e) => {
        if (!handleValidate()) {
            return;
        }
        e.preventDefault();
        setError('');
        setStatus('');
        setIsPending(true)
        await updateUserInfoAPI(user.apikey,
            {
                action: 'update_profile',
                name: input.name,
                phone: input.phone
            }).then((res) => {
                if (res?.data?.status === 'success') {
                    setStatus(res.data.message);
                    setIsPending(false)
                } else {
                    setError(res.data.message);
                    setIsPending(false)
                }
            });
    }
    const handleValidate = () => {
        setValidates([]);
        let isValid = true;
        if (input.name === '') {
            setValidates(prev => ([...prev, { name: ['New name is required'] }]));
            isValid = false;
        }
        if (input.phone.length <= 9) {
            setValidates(prev => ([...prev, { phone: ['Your phone number must be at least 10 digits'] }]));
            isValid = false;
        }
        return isValid;
    }
    const getErrorMessage = type => {
        const currentError = validates.find(validate => validate[type]);
        if (typeof currentError === 'undefined') {
            return [];
        }
        return currentError[type];
    }
    return (
        <>
            <HeaderDashboard />
            <Box sx={{ bgcolor: '#f5f4f3', height: '100vh', display: 'flex', justifyContent: 'center' }}>
                <Paper evelation={3} variant="outlined" square sx={{ width: '500px', height: 'fit-content', marginTop: '5%' }} >
                    <DialogTitle color="primary" sx={{ fontSize: '40px', textAlign: 'center', color: 'white', bgcolor: '#222222' }}>Edit Profile</DialogTitle>
                    <Box className={`${isPending === true ? 'pending' : ''}`} >
                        <CircularProgress className={`${isPending === true ? 'pending-icon__active' : 'pending-icon__inactive'}`} />
                        <Box sx={{ paddingTop: '10px', display: 'flex', justifyContent: "center", borderRadius: '4px', height: '240px' }}>
                            <Box>
                                <Stack spacing={2} width="400px">
                                    <TextField
                                        error={getErrorMessage('name').length > 0 ? true : false}
                                        helperText={getErrorMessage('name').length > 0 ? getErrorMessage('name').map(message => {
                                            return message
                                        }) : ''}
                                        margin="normal"
                                        id="name"
                                        label="New username"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        autoComplete="off"
                                        onChange={e => handleChangeInput(e.target.value, 'name')}
                                        defaultValue={user.name}
                                    />
                                    <TextField
                                        error={getErrorMessage('phone').length > 0 ? true : false}
                                        helperText={getErrorMessage('phone').length > 0 ? getErrorMessage('phone').map(message => {
                                            return message
                                        }) : ''}
                                        margin="normal"
                                        id="name"
                                        label="New phone number"
                                        type="number"
                                        fullWidth
                                        variant="standard"
                                        autoComplete="off"
                                        onChange={e => handleChangeInput(e.target.value, 'phone')}
                                        defaultValue={user.phone}

                                    />
                                </Stack>
                                {error &&
                                    <Typography mt={1} color="error" sx={{ fontSize: '20px' }} >
                                        {error}
                                    </Typography >
                                }
                                {status &&
                                    <Typography mt={1} color='#222222' sx={{ fontSize: '20px' }} >
                                        {status}
                                    </Typography >
                                }
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: "center" }} >
                            <Stack
                                direction="row"
                                spacing={20}
                            >
                                <Box mb={2} sx={{ display: 'flex', justifyContent: "center" }} >
                                    <Stack
                                        direction="row"
                                        spacing={15}
                                    >
                                        <Button onClick={() => { history.push(`/user=${user.apikey}`) }} sx={{ bgcolor: '#222222' }} startIcon={<CancelIcon />} variant="contained" color="error">Cancel</Button>
                                        <Button onClick={(e) => { handleFormSubmit(e) }} sx={{ bgcolor: '#222222' }} startIcon={<SaveIcon />} variant="contained" color="primary">Save</Button>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </>
    );
}
export default ProfilePage
