import React from "react"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../services/constants/app';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { resetPasswordApi } from "../../services/Api";
const ResetPassword = () => {
    const token = useParams();
    const history = useHistory();
    const [validates, setValidates] = React.useState([]);
    const [status, setStatus] = React.useState();
    const [error, setError] = React.useState();
    const [input, setInput] = React.useState({
        password: '',
        confirm: ''
    });
    const handleChangeInput = (value, type) => {
        setInput(prev => {
            return {
                ...prev,
                [type]: value
            }
        });
    }
    const handleSubmitForm = async (e) => {
        e.preventDefault();
        setError('');
        setStatus('');
        if (!handleValidate()) {
            return;
        }
        await resetPasswordApi(token.token, { 'password': input.password }).then((res) => {
            if (res?.data?.status === 'success') {
                setStatus(res.data.message)
            } else {
                setError(res.data.message)
            }
        })

    }
    const handleValidate = () => {
        setValidates([]);
        let isValid = true;
        if (input.password === '') {
            setValidates(prev => ([...prev, { password: ['New password is required'] }]));
            isValid = false;
        }
        if (input.password !== input.confirm) {
            setValidates(prev => ([...prev, { match: ['Password Not Match'] }]));
            isValid = false;
        }
        return isValid;
    }
    const getErrorMessage = type => {
        const currentError = validates.find(validate => validate[type]);
        if (typeof currentError === 'undefined') {
            return [];
        }
        return currentError[type];
    }
    return (
        <>
            <AppBar position="static" sx={{ height: "80px", bgcolor: '#222222' }}>
                <Container maxWidth="xl" sx={{ height: "80px" }}>
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, zIndex: "999" }}
                        >
                            <Link className="" to='/'><img className="img-fluid logo" alt="" src={`${BASE_URL}/images/logo.png`}></img></Link>
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        </Box>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                        >
                            <Link className="" to='/'><img className="img-fluid logo" alt="" src={`${BASE_URL}/images/logo.png`}></img></Link>

                        </Typography>
                    </Toolbar>
                </Container>
            </AppBar >
            <Box sx={{ bgcolor: '#f5f4f3', height: '100vh', display: 'flex', justifyContent: 'center' }}>
                <Paper evelation={3} variant="outlined" square sx={{ width: '500px', height: 'fit-content', marginTop: '5%' }} >
                    <DialogTitle color="primary" sx={{ fontSize: '40px', textAlign: 'center', color: 'white', bgcolor: '#222222' }}>Create New Password</DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <DialogContentText mt={2} sx={{ width: '400px', textAlign: 'center' }}>
                            Your new password must be different from previous used passwords.
                        </DialogContentText>
                    </Box>
                    <Box sx={{ paddingTop: '10px', display: 'flex', justifyContent: "center", borderRadius: '4px', height: '240px' }}>
                        <Box>
                            <Stack spacing={2} width="400px">
                                <TextField
                                    error={getErrorMessage('password').length > 0 ? true : false}
                                    helperText={getErrorMessage('password').length > 0 ? getErrorMessage('password').map(message => {
                                        return message
                                    }) : ''}
                                    required
                                    margin="normal"
                                    id="name"
                                    label="Enter your new password"
                                    type="password"
                                    fullWidth
                                    variant="standard"
                                    autoComplete="off"
                                    onChange={e => handleChangeInput(e.target.value, 'password')}

                                />
                                <TextField
                                    error={getErrorMessage('match').length > 0 ? true : false}
                                    helperText={getErrorMessage('match').length > 0 ? getErrorMessage('match').map(message => {
                                        return message
                                    }) : ''}
                                    required
                                    margin="normal"
                                    id="name"
                                    label="Confirm your new password"
                                    type="password"
                                    fullWidth
                                    variant="standard"
                                    autoComplete="off"
                                    onChange={e => handleChangeInput(e.target.value, 'confirm')}
                                />
                            </Stack>
                            {error &&
                                <Typography mt={1} color="error" sx={{ fontSize: '20px' }} >
                                    {error}
                                </Typography >
                            }
                            {status &&
                                <Typography mt={1} color='#222222' sx={{ fontSize: '20px' }} >
                                    {status}
                                </Typography >
                            }
                        </Box>
                    </Box>
                    <Box mb={2} sx={{ display: 'flex', justifyContent: "center" }} >
                        <Stack
                            direction="row"
                            spacing={15}
                        >
                            <Button onClick={() => { history.push('/') }} sx={{ bgcolor: '#222222' }} variant="contained" color="error">Back to login</Button>
                            <Button onClick={(e) => { handleSubmitForm(e) }} sx={{ bgcolor: '#222222' }} variant="contained" color="primary">Reset Password</Button>
                        </Stack>
                    </Box>
                </Paper>
            </Box>
        </>
    )
}
export default ResetPassword