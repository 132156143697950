import React from "react";
import StepWizard from "react-step-wizard";
import "./CreateProject.css";
import Step1 from "./Step1";
import Step2 from "./Step2";
import transitions from './transitions.less';
/* eslint react/prop-types: 0 */
const CreateProject = () => {
    const [projectId, setProjectId] = React.useState();
    const [title, setTitle] = React.useState();
    const [describe, setDescribe] = React.useState();
    const updateProjectId = (value) => {
        setProjectId(value);
    }
    const updateTitle = (value) => {
        setTitle(value);
    }
    const updateDescribe = (value) => {
        setDescribe(value);
    }
    return (
        <div className="create-project h-100">
            <div className="container-fluid p-0 h-100">
                <div className="row h-100">
                    <StepWizard
                        transitions={transitions.intro}
                        isHashEnabled>
                        <Step1 hashKey={'describe'} updateTitle={updateTitle} updateDescribe={updateDescribe} />
                        <Step2 hashKey={'upload'} updateProjectId={updateProjectId} title={title} describe={describe} />
                    </StepWizard>
                </div>
            </div>
        </div >
    )
}

export default CreateProject
