import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
//MUI
import HomeIcon from "@mui/icons-material/Home";
import SaveIcon from "@mui/icons-material/Save";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DropzoneArea } from "@pandemicode/material-ui-dropzone";
import * as PANOLENS from "panolens";
import React, { useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button as ButtonBootstrap } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { BsCursorFill, BsFillEraserFill } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import ReactQuill from "react-quill";
import { useHistory, useParams } from "react-router-dom";
import * as THREE from "three";
import DragControls from "three-dragcontrols";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/react-quill/dist/quill.snow.css";
import {
  createLabelAPI,
  createLinkAPI, deleteLabelAPI,
  deleteLinkAPI, loadLabelAPI,
  loadLinkAPI, loadProjectAPI, updateLabelAPI, updateLinkAPI, updateProjectAPI, uploadEditImageAPI, uploadMediaAPI
} from "../../services/Api";
import { BASE_URL } from "../../services/constants/app";
import { spotIcon } from "../Base64Icon";
import "./EditProject.css";

//
let isPicking = "";
let thumbnails = [];
const EditProject = () => {
  const id = useParams();
  const history = useHistory();
  let infospots = [];
  let infospot = null;
  const [onProgress, setOnProgress] = React.useState(0);
  const [project, setProject] = React.useState([]);
  const [label, setLabel] = React.useState();
  const [link, setLink] = React.useState();
  const [img, setImg] = React.useState();
  const [viewer, setViewer] = React.useState(null);
  const [pano, setPano] = React.useState(new PANOLENS.ImagePanorama(img));
  const [popuplink, setPopupLink] = React.useState(false);
  const [popupspot, setPopupSpot] = React.useState(false);
  const [position, setPosition] = React.useState(null);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isAdjustY, setIsAdjustY] = React.useState(false);
  const [isAdjustXLeft, setIsAdjustXLeft] = React.useState(false);
  const [isAdjustXRight, setIsAdjustXRight] = React.useState(false);
  const [isAdjustBotLeft, setIsAdjustBotLeft] = React.useState(false);
  const [isAdjustBotRight, setIsAdjustBotRight] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);
  //Render view and add panorama

  React.useEffect(async () => {
    loadProjectAPI(id.prj_id, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      setProject(res.data.data);
    });
    const initPanolens = new PANOLENS.Viewer({
      container: document.getElementById("editor-frame"),
      controlBar: true,
      output: "event",
      controlButtons: ["fullscreen"],
      autoHideInfospot: true,
      autoRotate: false,
    });
    setViewer(initPanolens);
    initPanolens.add(pano);
    pano.addEventListener("click", function (e) {
      handleClickOnPano(e);
    });
    initPanolens.addEventListener("position-output", function (e) {
      var a = e.position;
      setPosition(a);
    });
  }, []);
  React.useEffect(() => {
    let count = 0;
    thumbnails = [];
    project?.map((item) => {
      item.images.map((value, index) => {
        count++;
        thumbnails.push({
          id: count,
          key: value.id,
          thumbnailUrl: value.thumbnail_url,
          imageUrl: value.url,
        });
        setThumbnail(thumbnails);
        if (index === 0) {
          setImg(value.url);
          setPano(new PANOLENS.ImagePanorama(value.url));
          // setPano(new PANOLENS.ImagePanorama(`${BASE_URL}/images/avatar.png`));
        }
      });
    });

  }, [project]);
  React.useEffect(() => {
    if (viewer !== null) {
      viewer.remove(viewer.panorama);
      viewer.add(pano);
      viewer.setPanorama(pano);
      pano.addEventListener("click", function (e) {
        handleClickOnPano(e);
      });
      pano.addEventListener("progress", function (e) {
        let percent = (e.progress.loaded / e.progress.total) * 100;
        setOnProgress(percent);
      });
    }
    document.getElementById("editor-frame").style.cursor = "default";
    isPicking = "";
  }, [pano]);
  React.useEffect(() => {
    if (thumbnails.find((thumbnail) => thumbnail.imageUrl === img)) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      loadLabelAPI(
        thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key,
        config
      ).then((res) => {
        setLabel(res.data.data.label);
      });
      loadLinkAPI(
        thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key,
        config
      ).then((res) => {
        setLink(res.data.data.link);
      });
    }
  }, [img]);
  React.useEffect(() => {
    label?.map((value) => {
      const positions = JSON.parse(value.positions);
      if (value.text !== null) {
        infospot = new PANOLENS.Infospot(400, spotIcon.text);
        infospot.addHoverElement(
          document.querySelector(`#spot-ele-${value.id}`)
        );
      }
      if (value.image_url !== null) {
        infospot = new PANOLENS.Infospot(400, spotIcon.image);
        infospot.addHoverElement(
          document.querySelector(`#spot-ele-${value.id}`)
        );
      }
      if (value.video_url !== null) {
        infospot = new PANOLENS.Infospot(400, spotIcon.video);
        infospot.addHoverElement(
          document.querySelector(`video[name='original-${value.id}']`)
        );
        infospot.addEventListener("hoverenter", function () {
          let eleArray = document.getElementsByClassName("spot-vid-ele");
          if (eleArray.length > 2) {
            eleArray[1].remove();
          }
          document.querySelector(`#spot-ele-${value.id}`).removeAttribute("id");
          document.getElementById(`editor-frame`).click();
          document.getElementById(`spot-ele-${value.id}`).play();
        });
        infospot.addEventListener("hoverleave", function () {
          document.getElementById(`spot-ele-${value.id}`).pause();
          document
            .querySelector(`video[name='original-${value.id}']`)
            .setAttribute("id", `spot-ele-${value.id}`);
        });
      }
      infospot.position.set(positions[0], positions[1], positions[2]);
      infospot.uuid = value.id;
      if (
        thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key ===
        value.image_id
      ) {
        pano.add(infospot);
      }
    });
  }, [label]);
  React.useEffect(() => {
    link?.map((value) => {
      const positions = JSON.parse(value.positions);
      if (
        thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key ===
        value.image_id
      ) {
        const toPano = new PANOLENS.ImagePanorama(value.url);
        pano.link(
          toPano,
          new THREE.Vector3(positions[0], positions[1], positions[2]),
          400,
          spotIcon.link
        );
        pano.visible = true;
        pano.children.map((item, index) => {
          if (item.toPanorama !== null) {
            item.addHoverElement(
              document.querySelector(`#preview-link-${value.id}`)
            );
            item.uuid = value.id;
            item.addEventListener("click", function () {
              pano.addEventListener("leave", function () {
                document
                  .getElementById("editor-frame")
                  .classList.add("transition-start");
              });
              pano.addEventListener("leave-complete", function () {
                document
                  .getElementById("editor-frame")
                  .classList.add("transition-end");
                document
                  .getElementById("editor-frame")
                  .classList.remove("transition-start");
                document
                  .getElementById("editor-frame")
                  .classList.remove("transition-end");
              });
            });
          }
        });
        toPano.addEventListener("click", function (e) {
          handleClickOnPano(e);
        });
        toPano.addEventListener("load", function (e) {
          setImg(value.url);
          setPano(new PANOLENS.ImagePanorama(value.url));
        });
        viewer.add(pano, toPano);
        viewer.setPanorama(pano);
        document.getElementById("editor-frame").click();
      }
    });
  }, [link]);
  // Change img by thumbnails
  const imgClick = (imageUrl) => {
    document.querySelectorAll(".panolens-infospot").forEach((e) => e.remove());
    setImg(imageUrl);
    setPano(new PANOLENS.ImagePanorama(imageUrl));
  };
  const getPosition = (type) => {
    document.getElementById("editor-frame").style.cursor = "crosshair";
    isPicking = type;
  };
  // Add infospot
  const handleClickOnPano = (e) => {
    if (isPicking === "") return;
    const { offsetX, offsetY } = e.mouseEvent;
    if (offsetY < 282) {
      setIsAdjustY(!isAdjustY);
    } else {
      setIsAdjustY(false);
    }
    if (offsetX < 148) {
      setIsAdjustXLeft(!isAdjustXLeft);
    } else {
      setIsAdjustXLeft(false);
    }
    if (offsetX > 1392) {
      setIsAdjustXRight(!isAdjustXRight);
    } else {
      setIsAdjustXRight(false);
    }
    if (offsetX < 148 && offsetY > 617) {
      setIsAdjustBotLeft(!isAdjustBotLeft);
      setIsAdjustXLeft(false);
    } else {
      setIsAdjustBotLeft(false);
    }
    if (offsetX > 1392 && offsetY > 617) {
      setIsAdjustBotRight(!isAdjustBotRight);
      setIsAdjustY(false);
    } else {
      setIsAdjustBotRight(false);
    }
    const locationPopupLabel = document.querySelector(".picked-location-label");
    const locationPopupLink = document.querySelector(".picked-location-link");
    if (isPicking === "spot") {
      if (locationPopupLabel) {
        locationPopupLabel.style.top = `${offsetY - 70}px`;
        locationPopupLabel.style.left = `${offsetX - 34.5}px`;
      }
    } else {
      if (locationPopupLink) {
        locationPopupLink.style.top = `${offsetY - 70}px`;
        locationPopupLink.style.left = `${offsetX - 34.5}px`;
      }
    }
    setPopupLink(isPicking === "link");
    setPopupSpot(isPicking === "spot");
  };
  const closeForm = () => {
    setPopupLink(false);
    setPopupSpot(false);
    isPicking = "";
    document.getElementById("editor-frame").style.cursor = "default";
  };
  //add spot
  const AddInfoSpot = () => {
    let data = new FormData();
    const [input, setInput] = React.useState({
      title: "",
      description: "",
      embed: [],
      file: [],
    });
    const [option, setOption] = React.useState("text");
    const handleChangeInput = (value, type) => {
      setInput((prev) => {
        return {
          ...prev,
          [type]: value,
        };
      });
    };
    React.useEffect(() => {
      input.file.map((value) => {
        data.append("img[]", value);
      });
    }, [input.file]);
    React.useEffect(() => {
      input.embed.map((value) => {
        data.append("video", value);
      });
    }, [input.embed]);

    const saveSpot = async () => {
      if (position == null) {
        UseSnackbar(
          "Add spot failed ! You must hold Crtl before click",
          "error"
        );
        return;
      }
      switch (option) {
        case "text":
          if (input.title === "" || input.description === "") {
            UseSnackbar("Title and description can not be blank !", "error");
            setPopupSpot(false);
            isPicking = "";
            setPosition(null);
            return;
          }
          break;
        case "img":
          if (input.title === "" || input.file.length === 0) {
            UseSnackbar("Title and image can not be blank !", "error");
            setPopupSpot(false);
            isPicking = "";
            setPosition(null);
            return;
          }
          break;

        case "video":
          if (input.title === "" || input.embed.length === 0) {
            UseSnackbar("Title and video can not be blank !", "error");
            setPopupSpot(false);
            isPicking = "";
            setPosition(null);
            return;
          }
          break;
      }
      setOpenLoading(true);
      setPopupSpot(false);
      isPicking = "";
      setPosition(null);
      //
      data.append("title", input.title);
      data.append("positions_x", position.x);
      data.append("positions_y", position.y);
      data.append("positions_z", position.z);
      data.append("text", input.description);
      data.append(
        "image_id",
        thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key
      );
      data.append("project_id", project[0].id);
      data.append("video_url", input.embed);
      document
        .querySelectorAll(".panolens-infospot")
        .forEach((e) => e.remove());
      await createLabelAPI(data);

      pano.children = [];
      loadLabelAPI(
        thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key
      ).then((res) => {
        setLabel(res.data.data.label);
      });
      loadLinkAPI(
        thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key
      ).then((res) => {
        setLink(res.data.data.link);
      });
      viewer.options.autoHideInfospot = true;
      document.getElementById("editor-frame").style.cursor = "default";
      setOpenLoading(false);
      UseSnackbar("Add spot successfully !", "success");
    };
    return (
      <div className="preview-spot">
        <div
          className={`
                ${isAdjustY === false ? "" : "adjustY"}
                ${isAdjustXLeft === false ? "" : "adjustXleft"}
                ${isAdjustXRight === false ? "" : "adjustXRight"}
                ${isAdjustBotLeft === false ? "" : "adjustBotLeft"}
                ${isAdjustBotRight === false ? "" : "adjustBotRight"}
                `}
        >
          <Card sx={{ width: "300px", height: "310px", borderRadius: "5%" }}>
            <Box
              sx={{
                paddingTop: "5px",
                paddingBottom: "5px",
                borderBottom: "solid 1px #1c79d3",
                display: "flex",
                justifyContent: "space-around",
                bgcolor: "#222222",
              }}
            >
              <Button
                sx={{
                  width: "80px",
                  ":hover": { color: "white" },
                  color: `${option === "text" ? "white" : "black"}`,
                  bgcolor: `${option === "text" ? "transparent" : "white"}`,
                }}
                onClick={() => {
                  setInput({
                    title: "",
                    description: "",
                    embed: [],
                    file: [],
                  });
                  setOption("text");
                }}
              >
                Text
              </Button>
              <Button
                sx={{
                  width: "80px",
                  ":hover": { color: "white" },
                  color: `${option === "img" ? "white" : "black"}`,
                  bgcolor: `${option === "img" ? "transparent" : "white"}`,
                }}
                onClick={() => {
                  setInput({
                    title: "",
                    description: "",
                    embed: [],
                    file: [],
                  });
                  setOption("img");
                }}
              >
                Image
              </Button>
              <Button
                sx={{
                  width: "80px",
                  ":hover": { color: "white" },
                  color: `${option === "video" ? "white" : "black"}`,
                  bgcolor: `${option === "video" ? "transparent" : "white"}`,
                }}
                onClick={() => {
                  setInput({
                    title: "",
                    description: "",
                    embed: [],
                    file: [],
                  });
                  setOption("video");
                }}
              >
                Video
              </Button>
            </Box>
            <CardContent>
              <Stack
                spacing={2}
                sx={{ display: `${option === "text" ? "" : "none"}` }}
              >
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  onChange={(value) => {
                    handleChangeInput(value, "title");
                  }}
                  ref={questionGroupContentHtmlRef}
                  placeholder="Infospot title"
                />
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  onChange={(value) => {
                    handleChangeInput(value, "description");
                  }}
                  ref={questionGroupContentHtmlRef}
                  placeholder="Infospot description"
                />
              </Stack>
              <Stack
                spacing={2}
                sx={{ display: `${option === "img" ? null : "none"}` }}
              >
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  onChange={(value) => {
                    handleChangeInput(value, "title");
                  }}
                  ref={questionGroupContentHtmlRef}
                  placeholder="Infospot title"
                />
                <div className="toolsbox e-float-input e-input-group">
                  <DropzoneArea
                    dropzoneText={
                      input.file.length === 0
                        ? "Drop a image here or click"
                        : ""
                    }
                    filesLimit={1}
                    maxFileSize={35000000}
                    acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
                    onDrop={(files) => {
                      handleChangeInput(files, "file");
                    }}
                    useChipsForPreview
                    showAlerts={false}
                  />
                  <span className="e-float-line"></span>
                </div>
              </Stack>
              <Stack
                spacing={2}
                sx={{ display: `${option === "video" ? "" : "none"}` }}
              >
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  onChange={(value) => {
                    handleChangeInput(value, "title");
                  }}
                  ref={questionGroupContentHtmlRef}
                />
                <div className="toolsbox e-float-input e-input-group">
                  <DropzoneArea
                    dropzoneText={
                      input.embed.length === 0
                        ? "Drop a video here or click"
                        : ""
                    }
                    filesLimit={1}
                    maxFileSize={35000000}
                    onDrop={(files) => {
                      handleChangeInput(files, "embed");
                    }}
                    useChipsForPreview
                    showAlerts={false}
                  />
                  <span className="e-float-line"></span>
                </div>
              </Stack>
            </CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button
                sx={{ bgcolor: "#222222", color: "white", width: "90px" }}
                onClick={() => {
                  closeForm();
                }}
                size="small"
                startIcon={<CancelIcon />}
                variant="contained"
                color="error"
              >
                Cancel
              </Button>
              <Button
                sx={{ bgcolor: "#222222", color: "white", width: "90px" }}
                onClick={() => {
                  saveSpot();
                }}
                size="small"
                startIcon={<SaveIcon />}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
          </Card>
        </div>
        {option === "text" && (
          <img
            style={{ width: "44.8px", height: "44.8px" }}
            src={`${BASE_URL}/images/text-icon.png`}
          ></img>
        )}
        {option === "img" && (
          <img
            style={{ width: "44.8px", height: "44.8px" }}
            src={`${BASE_URL}/images/image-icon.png`}
          ></img>
        )}
        {option === "video" && (
          <img
            style={{ width: "44.8px", height: "44.8px" }}
            src={`${BASE_URL}/images/video-icon.png`}
          ></img>
        )}
      </div>
    );
  };
  //
  //Add link
  const AddLinkImage = () => {
    const [link, setLink] = React.useState({
      url: "",
    });
    const handleChangeInput = (value) => {
      setLink({ url: value });
    };
    const saveLink = async () => {
      let data = new FormData();
      if (position === null) {
        UseSnackbar(
          "Add spot failed ! You must hold Crtl before click",
          "error"
        );
        return;
      }
      const toPano = new PANOLENS.ImagePanorama(link.url);
      pano.link(toPano, new THREE.Vector3(position.x, position.y, position.z));
      pano.visible = true;
      toPano.addEventListener("click", function (e) {
        handleClickOnPano(e);
      });
      toPano.addEventListener("load", function (e) {
        setImg(link.url);
        setPano(new PANOLENS.ImagePanorama(link.url));
      });
      viewer.add(pano, toPano);
      viewer.setPanorama(toPano);
      isPicking = "";
      setPopupLink(false);
      setPosition(null);
      const currentImage = thumbnails.find(
        (thumbnail) => thumbnail.imageUrl === link.url
      );
      if (typeof currentImage !== "undefined") {
        setImg(link.url);
      }
      data.append("positions_x", position.x);
      data.append("positions_y", position.y);
      data.append("positions_z", position.z);
      data.append("image_id", img);
      data.append("link", link.url);
      await createLinkAPI(data);
      loadLinkAPI(
        thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key
      ).then((res) => {
        setLink(res.data.data.link);
        setLink(res.data.data.link);
      });
      viewer.options.autoHideInfospot = true;
      document.getElementById("editor-frame").style.cursor = "default";
      UseSnackbar("Add spot successfully !", "success");
    };
    return (
      <div className="preview-spot">
        <div
          className={`
                ${isAdjustY === false ? "" : "adjustY"}
                ${isAdjustXLeft === false ? "" : "adjustXleft"}
                ${isAdjustXRight === false ? "" : "adjustXRight"}
                ${isAdjustBotLeft === false ? "" : "adjustBotLeft"}
                ${isAdjustBotRight === false ? "" : "adjustBotRight"}
                `}
        >
          <Card sx={{ width: "300px", height: "260px", borderRadius: "5%" }}>
            <Box
              sx={{
                paddingTop: "5px",
                paddingBottom: "5px",
                bgcolor: "#222222",
                height: "47.5px",
                borderBottom: "solid 1px #1c79d3",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "25px",
                  textAlign: "center",
                  color: "white",
                  height: "fit-content",
                }}
              >
                Add Linkspot
              </Typography>
            </Box>
            <CardContent>
              <Box sx={{ height: "140px" }}>
                {thumbnails.length === 1 ? (
                  <Box>
                    <img
                      style={{ width: "268px", height: "140px" }}
                      src={`${BASE_URL}/images/no-image.jpg`}
                    ></img>
                  </Box>
                ) : (
                  <Carousel variant="dark">
                    {thumbnail.map((item, index) => {
                      return item.imageUrl === img ? (
                        ""
                      ) : (
                        <Carousel.Item key={index}>
                          <div className="img-wrapper">
                            <img
                              style={{
                                width: 268,
                                height: 134,
                                position: "relative",
                              }}
                              className="d-block w-100"
                              src={item.thumbnailUrl}
                              alt="First slide"
                              indicators={false}
                            />
                            <Checkbox
                              onChange={() => {
                                handleChangeInput(item.imageUrl);
                              }}
                              sx={{
                                position: "absolute",
                                zIndex: 9999,
                                "& .MuiSvgIcon-root": {
                                  fontSize: 28,
                                  color: "black",
                                },
                              }}
                            />
                          </div>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                )}
              </Box>
            </CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button
                onClick={() => {
                  closeForm();
                }}
                sx={{ width: "90px", bgcolor: "#222222", color: "white" }}
                size="small"
                startIcon={<CancelIcon />}
                variant="contained"
                color="error"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  saveLink();
                }}
                sx={{ width: "90px", bgcolor: "#222222", color: "white" }}
                size="small"
                startIcon={<SaveIcon />}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
          </Card>
        </div>
        <img
          style={{ width: "44.8px", height: "44.8px" }}
          src={`${BASE_URL}/images/link-icon.png`}
        ></img>
      </div>
    );
  };
  //Snackbar
  const [snackbar, setSnackbar] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(false);
  };
  const UseSnackbar = (message, type) => {
    setSnackbar(true);
    setMessage(message);
    setType(type);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  //

  //Drag and Drop Thumbnails
  const [thumbnail, setThumbnail] = React.useState([]);
  const handleOnDragEnd = (result) => {
    viewer.control.enabled = true;
    if (!result.destination) return;
    const items = Array.from(thumbnail);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setThumbnail(items);
    const image_position = items.map((value) => {
      return value.key;
    });
    let config = { action: "update_position", positions: image_position };
    updateProjectAPI(parseInt(id.prj_id), config, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
      },
    });
  };
  //
  //Pending Modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#222222",
    border: "1px solid #222222",
    boxShadow: 24,
    p: 4,
    color: "#ffffff",
  };
  window.addEventListener("keydown", (event) => {
    if (event.key === "Control") {
      handleClose();
    }
  });
  // Edit Infospot
  const [openEditInfo, setOpenEditInfo] = React.useState(false);
  const [spotType, setSpotType] = React.useState("");
  const [spotInfo, setSpotInfo] = React.useState({
    id: "",
    title: "",
    description: "",
    positions: "",
    image: [],
    video: [],
  });
  const [isPending, setIsPending] = React.useState(false);
  const handleChangeInputEditSpot = (value, type) => {
    setSpotInfo((prev) => {
      return {
        ...prev,
        [type]: value,
      };
    });
  };
  const modules = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        { align: [] },
        { color: [] },
        { background: [] },
        "clean",
      ],
    ],
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "align",
    "color",
    "background",
  ];
  const questionGroupContentHtmlRef = useRef(null);
  const handleClickOpenEditInfo = () => {
    setOpenEditInfo(true);
  };

  const handleCloseEditInfo = () => {
    setOpenEditInfo(false);
  };
  const SaveEditSpot = () => {
    let data = new FormData();
    document.querySelectorAll(".panolens-infospot").forEach((e) => e.remove());
    switch (spotType) {
      case "text":
        data = {
          title: spotInfo.title,
          description: spotInfo.description,
          type: spotType,
        };
        setIsPending(true);
        updateLabelAPI(spotInfo.id, data).then((res) => {
          if (res.data.status === "success") {
            UseSnackbar("Edit successfully !", "success");
          } else {
            UseSnackbar("Edit failed !", "error");
          }
          setIsPending(false);
          setOpenEditInfo(false);
          setIsEdit(false);
          viewer.options.autoHideInfospot = true;
          pano.children = [];
          loadLabelAPI(
            thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key
          ).then((res) => {
            setLabel(res.data.data.label);
          });
          loadLinkAPI(
            thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key
          ).then((res) => {
            setLink(res.data.data.link);
          });
          pano.toggleInfospotVisibility(true, 0);
          document.getElementById("editor-frame").style.cursor = "default";
        });
        break;
      case "image":
        spotInfo?.image?.map((image) => {
          data.append("img", image);
        });
        setIsPending(true);
        uploadMediaAPI(data).then((res) => {
          updateLabelAPI(spotInfo.id, {
            title: spotInfo.title,
            image_url: res.data.data,
            type: spotType,
          }).then((res) => {
            if (res.data.status === "success") {
              UseSnackbar("Edit successfully !", "success");
            } else {
              UseSnackbar("Edit failed !", "error");
            }
            setIsPending(false);
            setOpenEditInfo(false);
            setIsEdit(false);
            viewer.options.autoHideInfospot = true;
            pano.children = [];
            loadLabelAPI(
              thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key
            ).then((res) => {
              setLabel(res.data.data.label);
            });
            loadLinkAPI(
              thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key
            ).then((res) => {
              setLink(res.data.data.link);
            });
            pano.toggleInfospotVisibility(true, 0);
            document.getElementById("editor-frame").style.cursor = "default";
          });
        });
        break;
      case "video":
        spotInfo?.video?.map((video) => {
          data.append("video", video);
        });
        setIsPending(true);
        uploadMediaAPI(data).then((res) => {
          updateLabelAPI(spotInfo.id, {
            title: spotInfo.title,
            video_url: res.data.data,
            type: spotType,
          }).then((res) => {
            if (res.data.status === "success") {
              UseSnackbar("Edit successfully !", "success");
            } else {
              UseSnackbar("Edit failed !", "error");
            }
            setIsPending(false);
            setOpenEditInfo(false);
            setIsEdit(false);
            viewer.options.autoHideInfospot = true;
            pano.children = [];
            loadLabelAPI(
              thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key
            ).then((res) => {
              setLabel(res.data.data.label);
            });
            loadLinkAPI(
              thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key
            ).then((res) => {
              setLink(res.data.data.link);
            });
            pano.toggleInfospotVisibility(true, 0);
            document.getElementById("editor-frame").style.cursor = "default";
          });
        });
        break;
    }
  };
  //Edit linkspot
  const [openEditLink, setOpenEditLink] = React.useState(false);
  const [linkId, setLinkId] = React.useState({
    id: "",
  });
  const [editLink, setEditLink] = React.useState({
    url: "",
  });
  const handleCloseEditLink = () => {
    setOpenEditLink(false);
  };

  const handleChangeEditLink = (value) => {
    setEditLink({ url: value });
  };
  const saveEditLink = () => {
    if (editLink.url === "") {
      handleCloseEditLink();
      return;
    }
    let data = {
      image_url: editLink.url,
      type: "content",
    };
    document.querySelectorAll(".panolens-infospot").forEach((e) => e.remove());
    setIsPending(true);
    updateLinkAPI(linkId.id, data).then((res) => {
      handleCloseEditLink();
      if (res.data.status === "success") {
        UseSnackbar("Edit successfully !", "success");
      } else {
        UseSnackbar("Edit failed !", "error");
      }
      setIsPending(false);
      setIsEdit(false);
      viewer.options.autoHideInfospot = true;
      pano.children = [];
      loadLabelAPI(
        thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key
      ).then((res) => {
        setLabel(res.data.data.label);
      });
      loadLinkAPI(
        thumbnails.find((thumbnail) => thumbnail.imageUrl === img).key
      ).then((res) => {
        setLink(res.data.data.link);
      });
      pano.toggleInfospotVisibility(true, 0);
      document.getElementById("editor-frame").style.cursor = "default";
    });
  };
  // Drag Spot
  return (
    <>
      <Snackbar
        open={snackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={type}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Dialog open={openEditLink} onClose={handleCloseEditLink}>
        <Box
          sx={{ borderRadius: "5%" }}
          className={`${isPending === true ? "pending" : ""}`}
        >
          <DialogContent sx={{ padding: 0 }}>
            <Card sx={{ width: "300px", height: "260px" }}>
              <Box
                sx={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  bgcolor: "#222222",
                  height: "47.5px",
                  borderBottom: "solid 1px #1c79d3",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "25px",
                    textAlign: "center",
                    color: "white",
                    height: "fit-content",
                  }}
                >
                  Add Linkspot
                </Typography>
              </Box>
              <CardContent>
                <Box sx={{ height: "140px" }}>
                  {thumbnails.length === 1 ? (
                    <Box>
                      <img
                        style={{ width: "268px", height: "140px" }}
                        src={`${BASE_URL}/images/no-image.jpg`}
                      ></img>
                    </Box>
                  ) : (
                    <Carousel variant="dark">
                      {thumbnail.map((item, index) => {
                        return item.imageUrl === img ? (
                          ""
                        ) : (
                          <Carousel.Item key={index}>
                            <div className="img-wrapper">
                              <img
                                style={{
                                  width: 268,
                                  height: 134,
                                  position: "relative",
                                }}
                                className="d-block w-100"
                                src={item.thumbnailUrl}
                                alt="First slide"
                                indicators={false}
                              />
                              <Checkbox
                                onChange={() => {
                                  handleChangeEditLink(item.imageUrl);
                                }}
                                sx={{
                                  position: "absolute",
                                  zIndex: 9999,
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 28,
                                    color: "black",
                                  },
                                }}
                              />
                            </div>
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  )}
                </Box>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Button
                  onClick={() => {
                    handleCloseEditLink();
                    document
                      .querySelectorAll(".panolens-infospot")
                      .forEach((e) => (e.style.display = "none"));
                  }}
                  sx={{ width: "90px", bgcolor: "#222222", color: "white" }}
                  size="small"
                  startIcon={<CancelIcon />}
                  variant="contained"
                  color="error"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    saveEditLink();
                  }}
                  sx={{ width: "90px", bgcolor: "#222222", color: "white" }}
                  size="small"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Card>
          </DialogContent>
        </Box>
      </Dialog>
      <Dialog open={openEditInfo} onClose={handleCloseEditInfo}>
        <DialogTitle
          color="primary"
          sx={{
            fontSize: "40px",
            textAlign: "center",
            bgcolor: "#222222",
            color: "white",
          }}
        >
          Edit Infospot
        </DialogTitle>
        <Box className={`${isPending === true ? "pending" : ""}`}>
          <DialogContent sx={{ height: "257px", width: "500px" }}>
            <CircularProgress
              className={`${isPending === true
                ? "pending-icon__active"
                : "pending-icon__inactive"
                }`}
            />
            <Stack
              spacing={2}
              sx={{
                marginTop: "15px",
                display: `${spotType === "text" ? "" : "none"}`,
              }}
            >
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                ref={questionGroupContentHtmlRef}
                placeholder="Infospot title"
                defaultValue={spotInfo.title}
                onChange={(value) => {
                  handleChangeInputEditSpot(value, "title");
                }}
              />
              <div>
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  ref={questionGroupContentHtmlRef}
                  placeholder="Infospot description"
                  defaultValue={spotInfo.description}
                  onChange={(value) => {
                    handleChangeInputEditSpot(value, "description");
                  }}
                />
              </div>
            </Stack>
            <Stack
              spacing={2}
              sx={{
                marginTop: "15px",
                display: `${spotType === "image" ? "" : "none"}`,
              }}
            >
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                ref={questionGroupContentHtmlRef}
                placeholder="Infospot title"
                defaultValue={spotInfo.title}
                onChange={(value) => {
                  handleChangeInputEditSpot(value, "title");
                }}
              />
              <div className="editinfo-dropzone">
                <DropzoneArea
                  dropzoneText={"Drop a image here or click"}
                  filesLimit={1}
                  maxFileSize={35000000}
                  useChipsForPreview
                  showAlerts={false}
                  onDrop={(files) => {
                    handleChangeInputEditSpot(files, "image");
                  }}
                />
              </div>
            </Stack>
            <Stack
              spacing={2}
              sx={{
                marginTop: "15px",
                display: `${spotType === "video" ? "" : "none"}`,
              }}
            >
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                ref={questionGroupContentHtmlRef}
                placeholder="Infospot title"
                defaultValue={spotInfo.title}
                onChange={(value) => {
                  handleChangeInputEditSpot(value, "title");
                }}
              />
              <div className="editinfo-dropzone">
                <DropzoneArea
                  dropzoneText={"Drop a video here or click"}
                  filesLimit={1}
                  maxFileSize={35000000}
                  useChipsForPreview
                  showAlerts={false}
                  onDrop={(files) => {
                    handleChangeInputEditSpot(files, "video");
                  }}
                />
              </div>
            </Stack>
          </DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button
              onClick={() => {
                handleCloseEditInfo();
                document
                  .querySelectorAll(".panolens-infospot")
                  .forEach((e) => (e.style.display = "none"));
              }}
              sx={{ width: "90px", marginBottom: "10px", bgcolor: "#222222" }}
              size="small"
              startIcon={<CancelIcon />}
              variant="contained"
              color="error"
            >
              Close
            </Button>
            <Button
              sx={{ width: "90px", marginBottom: "10px", bgcolor: "#222222" }}
              size="small"
              startIcon={<SaveIcon />}
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => {
                SaveEditSpot();
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Dialog>
      <div className="picked-location-label">
        {popupspot && <AddInfoSpot />}
      </div>
      <div className="picked-location-link">
        {popuplink && <AddLinkImage />}
      </div>

      {label?.map((value) => {
        return (
          <>
            {value.text !== null && (
              <div
                id={`spot-ele-${value.id}`}
                className="spot-text-ele"
                style={{ display: "none" }}
              >
                <div className="text-ele__title">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: value.title,
                    }}
                  />
                </div>
                <div className="text-ele__describe">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: value.text,
                    }}
                  />
                </div>
              </div>
            )}
            {value.image_url !== null && (
              <div
                id={`spot-ele-${value.id}`}
                className="spot-img-ele"
                style={{ display: "none" }}
              >
                <div className="img-ele__title">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: value.title,
                    }}
                  />
                </div>
                <div className="img-ele__img">
                  <img className="img-fluid" src={value.image_url} />
                </div>
              </div>
            )}
            {value?.video_url !== null && (
              <video
                name={`original-${value.id}`}
                className="spot-vid-ele"
                style={{ display: "none" }}
                id={`spot-ele-${value.id}`}
                width="400"
                height="fit-content"
                controls="controls"
                controlsList="nofullscreen nodownload noplaybackrate"
                disablePictureInPicture=""
              >
                <source src={value.video_url} type="video/mp4" />
              </video>
            )}
          </>
        );
      })}
      {link?.map((value, index) => {
        const preview_url = thumbnails.find(
          (thumbnail) => thumbnail.imageUrl === value.url
        );
        return (
          <div
            style={{ display: "none" }}
            className="preview-link img-ele__img"
            id={`preview-link-${value.id}`}
          >
            <img
              className="img-fluid preview-image"
              src={preview_url.thumbnailUrl}
            ></img>
          </div>
        );
      })}
      {onProgress > 0 && onProgress !== 100 && (
        <LinearProgress
          sx={{ height: "10px" }}
          variant="determinate"
          value={onProgress}
        />
      )}
      <div id="editor-frame">
        <div className="container-fluid header-tools">
          <div className="row" style={{ width: "100%" }}>
            <div className="col-sm">
              <button
                onClick={() => {
                  history.push(`/user=${id.user_id}`);
                }}
                className="btn"
              >
                <HomeIcon
                  style={{ width: "36px", height: "36px", color: "white" }}
                />
              </button>
            </div>
            <div className="col-sm header-title">
              <p>
                {project?.map((value) => {
                  return value.title;
                })}
              </p>
            </div>
            <div className="col-sm header-button">
              <Stack
                direction="row"
                sx={{
                  height: "52px",
                  width: "max-content",
                  backgroundColor: "#222222",
                  float: "right",
                  borderRadius: "25px",
                }}
              >
                <Button
                  type="button"
                  title="Preview the project!"
                  sx={{
                    bgcolor: "red",
                    color: "white",
                    fontFamily: "nunito",
                    fontSize: "12px",
                    fontWeight: "bolder",
                    borderRadius: "25px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                  }}
                  onClick={() => {
                    history.push(
                      `/user=${id.user_id}/project=${id.prj_id}/preview`
                    );
                  }}
                >
                  Preview & Continue
                </Button>
                {isEdit === false ? (
                  <Button
                    type="button"
                    title="Edit infospot!"
                    disabled={isDelete || isEdit === true ? true : false}
                    onClick={() => {
                      setIsEdit(true);
                      viewer.options.autoHideInfospot = false;
                      setPopupLink(false);
                      setPopupSpot(false);
                      isPicking = "";
                      pano.children = [];
                      document
                        .querySelectorAll(".panolens-infospot")
                        .forEach((e) => e.remove());
                      label?.map((value) => {
                        let x, y, z;
                        const positions = JSON.parse(value.positions);
                        if (value.text !== null) {
                          infospot = new PANOLENS.Infospot(400, spotIcon.text);
                          infospot.addHoverElement(
                            document.querySelector(`#spot-ele-${value.id}`)
                          );
                        }
                        if (value.image_url !== null) {
                          infospot = new PANOLENS.Infospot(400, spotIcon.image);
                          infospot.addHoverElement(
                            document.querySelector(`#spot-ele-${value.id}`)
                          );
                        }
                        if (value.video_url !== null) {
                          infospot = new PANOLENS.Infospot(400, spotIcon.video);
                          infospot.addHoverElement(
                            document.querySelector(
                              `video[name='original-${value.id}']`
                            )
                          );
                        }
                        infospot.position.set(
                          positions[0],
                          positions[1],
                          positions[2]
                        );
                        infospot.uuid = value.id;
                        infospots.push(infospot);
                        if (
                          thumbnails.find(
                            (thumbnail) => thumbnail.imageUrl === img
                          ).key === value.image_id
                        ) {
                          infospots.map((spot) => {
                            if (spot._listeners.click[1] === undefined) {
                              spot.addEventListener("click", function () {
                                dragcontrols.enabled = false;
                                if (value.text !== null) {
                                  setSpotInfo({
                                    id: value.id,
                                    title: value.title,
                                    description: value.text,
                                  });
                                  setSpotType("text");
                                }
                                if (value.video_url !== null) {
                                  setSpotInfo({
                                    id: value.id,
                                    title: value.title,
                                  });
                                  setSpotType("video");
                                }
                                if (value.image_url !== null) {
                                  setSpotInfo({
                                    id: value.id,
                                    title: value.title,
                                  });
                                  setSpotType("image");
                                }
                                handleClickOpenEditInfo();
                              });
                            }
                            let dragcontrols = new DragControls(
                              [spot],
                              viewer.camera,
                              viewer.renderer.domElement
                            );
                            dragcontrols.addEventListener(
                              "dragstart",
                              function (event) {
                                viewer.addEventListener(
                                  "position-output",
                                  function (e) {
                                    x = e.position.x;
                                    y = e.position.y;
                                    z = e.position.z;
                                  }
                                );
                                viewer.control.enabled = false;
                                dragcontrols.enabled = false;
                              }
                            );
                            window.addEventListener("keydown", (event) => {
                              if (event.key === "Control") {
                                dragcontrols.enabled = true;
                              }
                            });
                            window.addEventListener("keyup", (event) => {
                              if (event.key === "Control") {
                                dragcontrols.enabled = false;
                              }
                            });
                            dragcontrols.addEventListener(
                              "drag",
                              function (event) {
                                if ((x, y, z)) {
                                  spot.position.set(x, y, z);
                                }
                                viewer.control.enabled = false;
                              }
                            );
                            dragcontrols.addEventListener(
                              "dragend",
                              function (event) {
                                let update = {
                                  positions_x: x,
                                  positions_y: y,
                                  positions_z: z,
                                  type: "position",
                                };
                                if ((x, y, z)) {
                                  updateLabelAPI(spot.uuid, update);
                                }
                                x = y = z = undefined;
                                viewer.control.enabled = true;
                              },
                              { passive: true }
                            );
                            pano.add(spot);
                            pano.toggleInfospotVisibility(true, 0);
                          });
                        }
                      });
                      link?.map((value) => {
                        let x, y, z;
                        let linkspot;
                        const positions = JSON.parse(value.positions);
                        if (
                          thumbnails.find(
                            (thumbnail) => thumbnail.imageUrl === img
                          ).key === value.image_id
                        ) {
                          linkspot = new PANOLENS.Infospot(400, spotIcon.link);
                          linkspot.position.set(
                            positions[0],
                            positions[1],
                            positions[2]
                          );
                          linkspot.addHoverElement(
                            document.querySelector(`#preview-link-${value.id}`)
                          );
                          linkspot.uuid = value.id;
                          linkspot.addEventListener("click", function () {
                            dragcontrolslink.enabled = false;
                            setOpenEditLink(true);
                            setLinkId({ id: value.id });
                          });
                          let dragcontrolslink = new DragControls(
                            [linkspot],
                            viewer.camera,
                            viewer.renderer.domElement
                          );
                          dragcontrolslink.addEventListener(
                            "dragstart",
                            function (event) {
                              viewer.addEventListener(
                                "position-output",
                                function (e) {
                                  x = e.position.x;
                                  y = e.position.y;
                                  z = e.position.z;
                                }
                              );
                              viewer.control.enabled = false;
                              dragcontrolslink.enabled = false;
                            }
                          );
                          window.addEventListener("keydown", (event) => {
                            if (event.key === "Control") {
                              dragcontrolslink.enabled = true;
                            }
                          });
                          dragcontrolslink.addEventListener(
                            "drag",
                            function (event) {
                              if ((x, y, z)) {
                                linkspot.position.set(x, y, z);
                              }
                              viewer.control.enabled = false;
                            }
                          );
                          dragcontrolslink.addEventListener(
                            "dragend",
                            function (event) {
                              let update = {
                                positions_x: x,
                                positions_y: y,
                                positions_z: z,
                                type: "position",
                              };
                              if ((x, y, z)) {
                                updateLinkAPI(linkspot.uuid, update);
                              }
                              x = y = z = undefined;
                              viewer.control.enabled = true;
                            }
                          );
                          pano.add(linkspot);
                          pano.toggleInfospotVisibility(true, 0);
                        }
                      });
                    }}
                  >
                    <FiEdit2
                      style={{ color: "white", width: "36px", height: "36px" }}
                    />
                  </Button>
                ) : (
                  <Button
                    type="button"
                    title="Save change"
                    sx={{ color: "white", fontFamily: "nunito" }}
                    onClick={() => {
                      viewer.options.autoHideInfospot = true;
                      pano.children = [];
                      document
                        .querySelectorAll(".panolens-infospot")
                        .forEach((e) => e.remove());
                      loadLabelAPI(
                        thumbnails.find(
                          (thumbnail) => thumbnail.imageUrl === img
                        ).key
                      ).then((res) => {
                        setLabel(res.data.data.label);
                      });
                      loadLinkAPI(
                        thumbnails.find(
                          (thumbnail) => thumbnail.imageUrl === img
                        ).key
                      ).then((res) => {
                        setLink(res.data.data.link);
                      });
                      pano.toggleInfospotVisibility(true, 0);
                      document.getElementById("editor-frame").style.cursor =
                        "default";
                      UseSnackbar("Saved !", "success");
                      setIsEdit(false);
                    }}
                  >
                    Save
                  </Button>
                )}
                {isDelete === false ? (
                  <Button
                    type="button"
                    title="Remove infospot!"
                    disabled={isEdit === true ? true : false}
                    onClick={() => {
                      viewer.options.autoHideInfospot = false;
                      setPopupLink(false);
                      setPopupSpot(false);
                      isPicking = "";
                      setIsDelete(!isDelete);
                      pano.children = [];
                      label?.map((value, index) => {
                        const positions = JSON.parse(value.positions);
                        if (value.text !== null) {
                          infospot = new PANOLENS.Infospot(400, spotIcon.text);
                        }
                        if (value.image_url !== null) {
                          infospot = new PANOLENS.Infospot(400, spotIcon.image);
                        }
                        if (value.video_url !== null) {
                          infospot = new PANOLENS.Infospot(400, spotIcon.video);
                        }
                        infospot.position.set(
                          positions[0],
                          positions[1],
                          positions[2]
                        );
                        infospot.uuid = value.id;
                        infospots.push(infospot);
                        if (
                          thumbnails.find(
                            (thumbnail) => thumbnail.imageUrl === img
                          ).key === value.image_id
                        ) {
                          infospots.map((spot, index) => {
                            if (spot._listeners.click[1] === undefined) {
                              spot.addEventListener("click", function click() {
                                setLabel((prev) => {
                                  return [
                                    ...prev.filter(
                                      (spot) => spot.id !== value.id
                                    ),
                                  ];
                                });
                                deleteLabelAPI(spot.uuid);
                                pano.remove(spot);
                                document
                                  .querySelector(
                                    `#editor-frame #spot-ele-${spot.uuid}`
                                  )
                                  .remove();
                              });
                            }
                            pano.add(spot);
                            pano.toggleInfospotVisibility(true, 0);
                          });
                        }
                      });
                      link?.map((data, index) => {
                        const positions = JSON.parse(data.positions);
                        if (
                          thumbnails.find(
                            (thumbnail) => thumbnail.imageUrl === img
                          ).key === data.image_id
                        ) {
                          pano.link(
                            "",
                            new THREE.Vector3(
                              positions[0],
                              positions[1],
                              positions[2]
                            ),
                            400,
                            spotIcon.link
                          );
                          pano.children.map((link, index) => {
                            if (link.toPanorama === "") {
                              if (link._listeners.click[2] === undefined) {
                                link.uuid = data.id;
                                link.addEventListener(
                                  "click",
                                  function click() {
                                    setLink((prev) => {
                                      return [
                                        ...prev.filter(
                                          (spot) => spot.id !== link.id
                                        ),
                                      ];
                                    });
                                    deleteLinkAPI(link.uuid);
                                    pano.remove(link);
                                  }
                                );
                              }
                            }
                          });
                          pano.visible = true;
                          pano.toggleInfospotVisibility(true, 0);
                        }
                      });
                    }}
                  >
                    <BsFillEraserFill
                      style={{ color: "white", width: "36px", height: "36px" }}
                    />
                  </Button>
                ) : (
                  <Button
                    type="button"
                    title="Save change"
                    sx={{ color: "white", fontFamily: "nunito" }}
                    onClick={() => {
                      viewer.options.autoHideInfospot = true;
                      setIsDelete(!isDelete);
                      pano.children = [];
                      document
                        .querySelectorAll(".panolens-infospot")
                        .forEach((e) => e.remove());
                      loadLabelAPI(
                        thumbnails.find(
                          (thumbnail) => thumbnail.imageUrl === img
                        ).key
                      ).then((res) => {
                        setLabel(res.data.data.label);
                      });
                      loadLinkAPI(
                        thumbnails.find(
                          (thumbnail) => thumbnail.imageUrl === img
                        ).key
                      ).then((res) => {
                        setLink(res.data.data.link);
                      });
                      pano.toggleInfospotVisibility(true, 0);
                      document.getElementById("editor-frame").style.cursor =
                        "default";
                      UseSnackbar("Saved !", "success");
                    }}
                  >
                    Save
                  </Button>
                )}
                <Button
                  type="button"
                  title="Add infospot"
                  onClick={() => {
                    setPopupLink(false);
                    handleOpen();
                    getPosition("spot");
                    viewer.options.autoHideInfospot = false;
                  }}
                  disabled={isDelete || isEdit === true ? true : false}
                >
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography id="modal-modal-description">
                        Hold Control key (Ctrl) until click anywhere you want to
                        add spot.
                      </Typography>
                    </Box>
                  </Modal>
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAABT5JREFUaEPVml1oI1UUx29iMrUzPiRoujR5E2lp+2CDruwGFLQRQbCgPik+LZEUujR5UvwAEfxAn9piocHgk6gvu0IFQUwVdiG7WGXqS0qL+JaUbZXkwZnqpKZyxpzh5vTORyZTawaGyWRm7pzf/M8598y9E2LBLKEBmzkZ8Ho2iAH0Wrd9tJUa7bbvibFfECdj8RjdUkPQcLqF83xD9QPCn8sbS3/Dvuhc3kj4za8I4QVOqJAXECcANBq24S4A/5/ophSiQ6DwOFXIMY7cQCgEbyRvPEDgSs/hYUQQAIIwTlAi17PadgKxg8AnD9u7OADcp+qIQNDgv7sQCIP7AEyh7JKF+b8diBMEGowQsDXXfD4fX1payiaTycckSUpFo9GxSCRyAW50fHx8p91uHxiGUW80GjdWV1crpVKpyRgD43EF4/nfnmFEICII3nXQ8EgXIKKq6tMTExNXZFm+yBiD/70sx7qub+3t7X2STqe/BtYuBG6pWnzsnHIzJxD0dYSwnnzX2EilUrmUyWTeHB0dfciL5XbnHB0d/VStVt/JZrO3u0A8DA9klwhOuZYolZ4CSKVSI6qqvp1IJK4MAkCvPTw8BHXeqtfrf9kAiVK2MEZEWQlAwF1gjeZyuXuXl5dLiqI8GiQEtqVp2s1isZgvl8u/M8baBEiU1U6B8GrwMcFD3Le2trYhSdIDZwGBbRqG8cvi4uJ8uVz+jcBgdkMgK1bcspMFkUql7t7d3f3srJSgDwaUmZycfLFer/9pA9PjZqL6iA/uKLgTrAcHB+8GHRNuqkLMjI2NvdEFATeDlQa/qYoouLGfQDWkSqWSmZub+9LtxmdxfHNz89lsNltljBlO8UJBRGqM6Lq+MWiK9QsJqVmW5XnGGGQyO1VMRWiQY5YCl5JUVZ2fnZ392I8h31W32Kvvr5iXfvh6kT1++WE/zbDt7e2X0+n0RlcVzGR8OWNC0ArWCnDG2IimaddkWb7sx4KLz7zEGncOzUuTFxJs66tP/TTDdF2/pSjK85wq0GHCaqVjCtKjRj6fT6yvr//cR9nRY2hQIGD0wsLCg6VSCZ4KxAqvipm9qBo9ILVa7YWpqallX4+RMRaUa8H9d3Z2itPT05/buZcIBFPuSLPZXIvFYs/5BQnyulardT0ejy/aBL2liDBbaZp2XZblS0Ea5LctXddvK4oCD1WYvUQVrpmtINANw7gZjUbv93vzIF2r3W7/KkkS1HcAgnFidY4UxOoEAaTT6eyFQiHFL0iAwc5OTk60cDg8wYHwmavjBrIbCoXu8QuSeuSpnkvrP3zjtykA+SMcDk96BYGsFZhrBQnSr2shCMBAZzhQsAcJ4jXYceSjB6TZbH4Ui8WgR/W1BAnSarWuxePxq27plwcx3wQhcw3aIQYJUqvVCjMzM1946RABJtASJUCQvkoUVCWwojEokH6KRogBoXsNUsYH1Y94LeMRAkHwDdGqufy+WH1/60f2ynv/1pwfvFZgT2Rg/K6/pZ8XKx6EqgJuNnSvurx7UVWGZvBBpArADN1wEIJQVXpgcrncUAzQUVVE8TIUQ6ZUFayMh3IQWwQzlNMKPIiof+Fnq/73Ez12MLTDFE69FQqFJ8fHx2HqLWkz9dbY39+/sbKy8u1ZT71h1+s0Us8nAjoth8f4UUxsE0fQ/7PJUDcY/hWZV8ptrv1cpqftYERpmqogUoOqQtWx+xqCv862UOPdx62ao67GA4ng6IM49084eEAKbgdHEwbfxrl/VEMVc4LyAmLnMr6+3erHtZxcb9B2fBnPG/QPXqhLcOE4FdQAAAAASUVORK5CYII="></img>
                </Button>
                <Button
                  type="button"
                  title="Edit linkspot!"
                  disabled={isDelete || isEdit === true ? true : false}
                  onClick={() => {
                    setPopupSpot(false);
                    handleOpen();
                    getPosition("link");
                    viewer.options.autoHideInfospot = false;
                  }}
                >
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography id="modal-modal-description">
                        Hold Control key (Ctrl) until click anywhere you want to
                        add spot.
                      </Typography>
                    </Box>
                  </Modal>
                  <BsCursorFill
                    style={{ color: "white", width: "36px", height: "36px" }}
                  />
                </Button>
              </Stack>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="thumbnail fixed-bottom  col-lg-12 col-md-6 col-sm-6 col-xs-6 thumbnail-nav-wrapper"
            onMouseEnter={() => {
              viewer.OrbitControls.mouseButtons.ORBIT = 1;
            }}
            onMouseLeave={() => {
              viewer.OrbitControls.mouseButtons.ORBIT = 0;
            }}
          >
            <div className="backArrow arrow">
              <button
                style={{ width: "50px" }}
                className="carousel-control-prev"
                type="button"
                onClick={() => {
                  document
                    .querySelectorAll(".panolens-infospot")
                    .forEach((e) => e.remove());
                  setPopupSpot(false);
                  setPopupLink(false);
                  isPicking = "";
                  const currentImage = thumbnails.find(
                    (thumbnail) => thumbnail.imageUrl === img
                  );
                  if (typeof currentImage == "undefined") {
                    return;
                  }
                  const nextImage = thumbnails.find(
                    (thumbnail) => thumbnail.id === currentImage.id - 1
                  );
                  if (typeof nextImage == "undefined") {
                    imgClick(thumbnails[thumbnails.length - 1].imageUrl);
                    return;
                  }
                  imgClick(nextImage.imageUrl);
                }}
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
            </div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="thumbnail-list" direction="horizontal">
                {(provided) => (
                  <div
                    className="thumbnail-list"
                    style={{ display: "flex", flexWrap: "wrap" }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {thumbnail.map((item, index) => {
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={`test-${item.id}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="thumbnail-wrapper"
                            >
                              <ButtonBootstrap
                                className="thumbnail-image-remove"
                                style={{
                                  display: `${thumbnails.length === 1 ? "none" : ""
                                    }`,
                                }}
                                variant="danger"
                                onClick={() => {
                                  setPopupSpot(false);
                                  setPopupLink(false);
                                  isPicking = "";
                                  let config = {
                                    action: "remove_image",
                                    image_id: item.key,
                                  };
                                  updateProjectAPI(
                                    parseInt(id.prj_id),
                                    config,
                                    {
                                      headers: {
                                        Authorization: `Bearer ${localStorage.getItem(
                                          "token"
                                        )}`,
                                        Accept: "application/json",
                                      },
                                    }
                                  );
                                  setProject((prev) => {
                                    const currentProject = prev.find(
                                      (pj) => pj.id === parseInt(id.prj_id)
                                    );
                                    return [
                                      ...prev.filter(
                                        (pj) => pj.id !== parseInt(id.prj_id)
                                      ),
                                      {
                                        ...currentProject,
                                        images: currentProject.images.filter(
                                          (img) => img.id !== item.key
                                        ),
                                      },
                                    ];
                                  });
                                  loadLabelAPI(
                                    thumbnails.find(
                                      (thumbnail) => thumbnail.imageUrl === img
                                    ).key
                                  ).then((res) => {
                                    setLabel(res?.data?.data?.label);
                                  });
                                  loadLinkAPI(
                                    thumbnails.find(
                                      (thumbnail) => thumbnail.imageUrl === img
                                    ).key
                                  ).then((res) => {
                                    setLink(res?.data?.data?.link);
                                  });
                                }}
                              >
                                <CloseIcon className="thumbnail-image-remove-icon " />
                              </ButtonBootstrap>
                              {item.thumbnailUrl === null ? (
                                <img
                                  className={`d-block img-fluid thumbnail-image ${item.imageUrl === img ? "active" : ""
                                    } `}
                                  onClick={() => {
                                    imgClick(item.imageUrl);
                                    setPopupSpot(false);
                                    setPopupLink(false);
                                    isPicking = "";
                                    setIsEdit(false);
                                    setIsDelete(false);
                                    setIsPending(false);
                                  }}
                                  id="img"
                                  src={`${BASE_URL}/images/default.png`}
                                />
                              ) : (
                                <img
                                  className={`d-block img-fluid thumbnail-image ${item.imageUrl === img ? "active" : ""
                                    } `}
                                  onClick={() => {
                                    imgClick(item.imageUrl);
                                    setPopupSpot(false);
                                    setPopupLink(false);
                                    isPicking = "";
                                    setIsEdit(false);
                                    setIsDelete(false);
                                    setIsPending(false);
                                  }}
                                  id="img"
                                  src={item.thumbnailUrl}
                                />
                              )}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Modal
              open={openLoading}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-description" align="center">
                  Loading...
                </Typography>
              </Box>
            </Modal>
            <div className="thumbnail-add-image">
              <ButtonBootstrap
                className="thumbnail-image-add-button"
                title="Add Image"
                variant="dark"
                onClick={() => {
                  document.getElementById("file").click();
                }}
              >
                <AddIcon className="thumbnail-image-add-button-icon " />
                <input
                  id="file"
                  type="file"
                  style={{ display: "none" }}
                  onChange={async (e) => {
                    if (e.target.files[0] === undefined) return;
                    setPopupLink(false);
                    setPopupSpot(false);
                    isPicking = "";
                    const data = new FormData();
                    setOpenLoading(true);
                    data.append("user_id", id.user_id);
                    data.append("img[]", e.target.files[0]);
                    await uploadEditImageAPI(data, {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                        Accept: "application/json",
                      },
                    }).then((res) => {
                      let config = {
                        action: "add_image",
                        images_id: res.data.id,
                      };
                      updateProjectAPI(parseInt(id.prj_id), config, {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "token"
                          )}`,
                          Accept: "application/json",
                        },
                      });
                      setProject((prev) => {
                        const currentProject = prev.find(
                          (pj) => pj.id === parseInt(id.prj_id)
                        );
                        return [
                          {
                            ...currentProject,
                            images: [
                              ...currentProject.images,
                              {
                                id: res.data.id,
                                label: [],
                                link: [],
                                thumbnail_url: res.data.thumbnail_url,
                                url: res.data.url,
                              },
                            ],
                          },
                        ];
                      });
                      pano.add(new PANOLENS.ImagePanorama(res.data.url));
                      setOpenLoading(false);
                    });
                    loadLabelAPI(
                      thumbnails.find((thumbnail) => thumbnail.imageUrl === img)
                        .key
                    ).then((res) => {
                      setLabel(res?.data?.data?.label);
                    });
                    loadLinkAPI(
                      thumbnails.find((thumbnail) => thumbnail.imageUrl === img)
                        .key
                    ).then((res) => {
                      setLink(res?.data?.data?.link);
                    });
                  }}
                />
              </ButtonBootstrap>
            </div>
            <div className="nextArrow arrow">
              <button
                style={{ width: "50px" }}
                className="carousel-control-next"
                type="button"
                onClick={() => {
                  document
                    .querySelectorAll(".panolens-infospot")
                    .forEach((e) => e.remove());
                  setPopupSpot(false);
                  setPopupLink(false);
                  isPicking = "";
                  const currentImage = thumbnails.find(
                    (thumbnail) => thumbnail.imageUrl === img
                  );
                  if (typeof currentImage == "undefined") {
                    return;
                  }
                  const nextImage = thumbnails.find(
                    (thumbnail) => thumbnail.id === currentImage.id + 1
                  );
                  if (typeof nextImage == "undefined") {
                    imgClick(thumbnails[0].imageUrl);
                    return;
                  }
                  imgClick(nextImage.imageUrl);
                }}
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditProject;
