import React from 'react'
import { updateUserInfoAPI } from '../../services/Api';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useHistory, useParams } from 'react-router-dom';
import { logoutAPI } from '../../services/Api';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { getUserInfoAPI } from '../../services/Api';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import HeaderDashboard from '../../pages/dashboard/header/';
const ChangePassword = () => {
    React.useEffect(async () => {
        await getUserInfoAPI(id.user_id, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Accept': 'application/json',
            }
        }).then((res) => {
            setUser(res.data.data)
        })
    }, []);
    const id = useParams();
    const history = useHistory();
    const [status, setStatus] = React.useState();
    const [error, setError] = React.useState();
    const [validates, setValidates] = React.useState([]);
    const [user, setUser] = React.useState({});
    const [isPending, setIsPending] = React.useState(false);
    const [isDone, setIsDone] = React.useState(false);
    const [input, setInput] = React.useState({
        password: '',
        confirm: ''
    });
    const handleChangeInput = (value, type) => {
        setInput(prev => {
            return {
                ...prev,
                [type]: value
            }
        });
    }
    const handeFormSubmit = async (e) => {
        if (!handleValidate()) {
            return;
        }
        e.preventDefault();
        setError('');
        setStatus('');

        if (confirm('Are you sure to change your current password? You must re-login after this process is done')) { // eslint-disable-line
            setIsPending(true)
            await updateUserInfoAPI(user.apikey,
                {
                    action: 'change_password',
                    password: input.password,
                }).then((res) => {
                    if (res?.data?.status === 'success') {
                        setStatus(res.data.message);
                        setIsPending(false);
                        setIsDone(true);
                        logoutAPI({
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                'Accept': 'application/json',
                            }
                        }).then(() => {
                            localStorage.clear();
                            setTimeout(() => { history.push('/') }, 3000)
                        })

                    } else {
                        setError(res.data.message);
                        setIsPending(false)

                    }
                });
        }

    }
    const handleValidate = () => {
        setValidates([]);
        let isValid = true;
        if (input.password === '') {
            setValidates(prev => ([...prev, { password: ['New password is required'] }]));
            isValid = false;
        }
        if (input.password !== input.confirm) {
            setValidates(prev => ([...prev, { match: ['Password does not match'] }]));
            isValid = false;
        }
        return isValid;
    }
    const getErrorMessage = type => {
        const currentError = validates.find(validate => validate[type]);
        if (typeof currentError === 'undefined') {
            return [];
        }
        return currentError[type];
    }
    return (
        <>
            <HeaderDashboard />
            <Box sx={{ bgcolor: '#f5f4f3', height: '100vh', display: 'flex', justifyContent: 'center' }}>
                <Paper evelation={3} variant="outlined" square sx={{ width: '500px', height: 'fit-content', marginTop: '5%' }} >
                    <DialogTitle color="primary" sx={{ fontSize: '40px', textAlign: 'center', color: 'white', bgcolor: '#222222' }}>Change Password</DialogTitle>
                    <Box className={`${isPending === true ? 'pending' : ''}`} >
                        <CircularProgress className={`${isPending === true ? 'pending-icon__active' : 'pending-icon__inactive'}`} />
                        <Box sx={{ paddingTop: '10px', display: 'flex', justifyContent: "center", borderRadius: '4px', height: '240px' }}>
                            <Box>
                                <Stack spacing={2} width="400px">
                                    <TextField
                                        error={getErrorMessage('password').length > 0 ? true : false}
                                        helperText={getErrorMessage('password').length > 0 ? getErrorMessage('password').map(message => {
                                            return message
                                        }) : ''}
                                        required
                                        margin="normal"
                                        id="name"
                                        label="New Password"
                                        type="password"
                                        fullWidth
                                        variant="standard"
                                        autoComplete="off"
                                        onChange={e => handleChangeInput(e.target.value, 'password')}
                                    />
                                    <TextField
                                        error={getErrorMessage('match').length > 0 ? true : false}
                                        helperText={getErrorMessage('match').length > 0 ? getErrorMessage('match').map(message => {
                                            return message
                                        }) : ''}
                                        required
                                        margin="normal"
                                        id="name"
                                        label="Confirm New Password"
                                        type="password"
                                        fullWidth
                                        variant="standard"
                                        autoComplete="off"
                                        onChange={e => handleChangeInput(e.target.value, 'confirm')}
                                    />
                                </Stack>
                                {error &&
                                    <Typography mt={1} color="error" sx={{ fontSize: '20px' }} >
                                        {error}
                                    </Typography >
                                }
                                {status &&
                                    <Typography mt={1} color='#222222' sx={{ fontSize: '20px' }} >
                                        {status}
                                    </Typography >
                                }
                                {isDone &&
                                    <Typography mt={1} color='#222222' sx={{ fontSize: '20px' }} >
                                        Your are being redirected to login page soon...
                                    </Typography >
                                }
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: "center" }} >
                            <Stack
                                direction="row"
                                spacing={20}
                            >
                                <Box mb={2} sx={{ display: 'flex', justifyContent: "center" }} >
                                    <Stack
                                        direction="row"
                                        spacing={15}
                                    >
                                        <Button onClick={() => { history.push(`/user=${user.apikey}`) }} sx={{ bgcolor: '#222222' }} startIcon={<CancelIcon />} variant="contained" color="error">Cancel</Button>
                                        <Button onClick={(e) => { handeFormSubmit(e) }} sx={{ bgcolor: '#222222' }} startIcon={<SaveIcon />} variant="contained" color="primary">Save</Button>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </>
    );
}
export default ChangePassword