import AccountTreeIcon from '@mui/icons-material/AccountTree';
//MUI
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CodeIcon from '@mui/icons-material/Code';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SaveIcon from '@mui/icons-material/Save';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MuiAlert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/material/Modal';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory, useParams } from 'react-router-dom';
import '../../../../node_modules/@trendmicro/react-sidenav/dist/react-sidenav.css';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { deleteProjectAPI, loadAllProjectAPI, searchAPI, updateProjectAPI } from "../../../services/Api";
import { BASE_URL } from "../../../services/constants/app";
import "./dashboard-body.css";
import usePagination from "./Pagination";

const DashboardMain = () => {
    let item = [];
    let count = 0;
    const id = useParams();
    const [project, setProject] = React.useState([]);
    const [isCopiedSocial, setIsCopiedSocial] = React.useState(false);
    const [isCopiedAPI, setIsCopiedAPI] = React.useState(false);
    const [isCopiedEmbed, setIsCopiedEmbed] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const history = useHistory();
    const [status, setStatus] = React.useState();
    const [error, setError] = React.useState();
    // const [projectId, setProjectId] = React.useState({});
    const [validates, setValidates] = React.useState([]);
    const [visible, setVisible] = React.useState(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#222222',
        border: '1px solid #222222',
        boxShadow: 24,
        p: 4,
        color: '#ffffff',
    };
    const [projectData, setProjectData] = React.useState({
        id: '',
        title: '',
        describe: '',
        product_url: ''
    });

    const [isPending, setIsPending] = React.useState(false);

    const handleCloseDelete = () => {
        setVisible(false);
    };
    //snackbar
    const [copy, setCopy] = React.useState(false);
    const [deleted, setDeleted] = React.useState(false);
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDeleted(false);
        setEditInfo(false);
        setCopy(false);
    };
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    //
    React.useEffect(() => {
        if (id.user_id !== localStorage.getItem('user')) {
            localStorage.clear();
            history.push('/');
        } else {
            loadAllProjectAPI(id.user_id,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    }
                }
            ).then((res) => {
                setProject(res.data.data);
            }).catch((error) => {
                if (error) {
                    localStorage.clear();
                    history.push('/');
                }
            })
        }

    }, []);
    //Search
    const noResult = [
        {
            title: "Your search did not match any projects."
        }
    ]
    const handleSearchInput = (input) => {
        const config = localStorage.getItem('user');
        switch (input) {
            case '':
                searchAPI('empty', config).then((res) => {
                    setProject(res.data.data);
                })
                break;
            default:
                searchAPI(input, config).then((res) => {
                    setProject(res.data.data);
                })
                break;

        }
    }
    let data =
        project?.map((value, index) => {
            return (
                {
                    key: index,
                    id: value.id,
                    title: value.title,
                }
            )

        })

    //Validate
    const getErrorMessage = type => {
        const currentError = validates.find(validate => validate[type]);
        if (typeof currentError === 'undefined') {
            return [];
        }
        return currentError[type];
    }
    const handleValidate = () => {
        setValidates([]);
        let isValid = true;
        if (input.title === '') {
            setValidates(prev => ([...prev, { title: ['Title cannot be blank'] }]));
            isValid = false;
        }
        if (input.describe === '') {
            setValidates(prev => ([...prev, { describe: ['Description cannot be blank'] }]));
            isValid = false;
        }
        return isValid;
    }
    //Edit info Modal
    const [editInfo, setEditInfo] = React.useState(false);
    const [input, setInput] = React.useState({
        title: '',
        describe: '',
    });
    const handleChangeInput = (value, type) => {
        setInput(prev => {
            return {
                ...prev,
                [type]: value
            }
        });
    }
    const [openEditInfo, setOpenEditInfo] = React.useState(false);
    const handleOpenEditInfo = () => {
        setOpenEditInfo(true);
    };

    const handleCloseEditInfo = () => {
        setOpenEditInfo(false);
        setValidates([]);
    };
    const handleFormSubmit = async (user_id, e) => {
        e.preventDefault();
        if (!handleValidate()) {
            return;
        }
        const title = input.title;
        const description = input.describe
        let config = {
            title: title,
            describe: description,
            action: 'edit_infomation'
        }
        setIsPending(true)
        await updateProjectAPI(user_id, config).then((res) => {
            if (res?.data?.status === 'success') {
                setStatus(res.data.message);
                setEditInfo(true);
                setIsPending(false);
                setOpenEditInfo(false)
            } else {
                setError(res.data.message);
                setIsPending(false);
            }
        });
        await loadAllProjectAPI(id.user_id,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            }
        ).then((res) => {
            if (res?.data?.status === 'success') {
                setProject(res.data.data);
            }
        })
    }
    const editInfoModal = () => {

        return (
            <Dialog open={openEditInfo} onClose={handleCloseEditInfo} >
                <DialogTitle color="primary" sx={{ fontSize: '40px', textAlign: 'center', bgcolor: '#222222', color: 'white' }}>
                    Edit title and description
                </DialogTitle>
                <Box className={`${isPending === true ? 'pending' : ''}`} >
                    <CircularProgress className={`${isPending === true ? 'pending-icon__active' : 'pending-icon__inactive'}`} />
                    <DialogContent sx={{ height: '200px', width: '500px', }}>
                        <TextField
                            error={getErrorMessage('title').length > 0 ? true : false}
                            helperText={getErrorMessage('title').length > 0 ? getErrorMessage('title').map(message => {
                                return message
                            }) : ''}
                            defaultValue={projectData.title}
                            margin="dense"
                            id="name"
                            label="Title"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={(e) => { handleChangeInput(e.target.value, 'title') }}
                        />
                        <TextField
                            error={getErrorMessage('describe').length > 0 ? true : false}
                            helperText={getErrorMessage('describe').length > 0 ? getErrorMessage('describe').map(message => {
                                return message
                            }) : ''}
                            defaultValue={projectData.describe}
                            margin="dense"
                            id="describe"
                            label="Description"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={(e) => { handleChangeInput(e.target.value, 'describe') }}
                        />
                        {error &&
                            <Typography mt={1} color='#222222' sx={{ fontSize: '20px', color: 'red' }} >
                                {error}
                            </Typography >
                        }
                    </DialogContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}>
                        <Button onClick={() => { handleCloseEditInfo(); }} sx={{ width: '90px', marginBottom: '10px', bgcolor: '#222222' }} size='small' startIcon={<CancelIcon />} variant="contained" color="error">Close</Button>
                        <Button sx={{ width: '90px', marginBottom: '10px', bgcolor: '#222222' }}
                            size='small'
                            startIcon={<SaveIcon />}
                            type='submit'
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                                handleFormSubmit(projectData.id, e)
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        )
    }
    //Get Iframe Modal

    const [openEmbed, setOpenEmbed] = React.useState(false);
    const [iframe, setIframe] = React.useState(`<iframe width='' height='' src="" frameborder='0' allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>`);
    const handleOpenEmbed = () => {
        setOpenEmbed(true)
    }
    const handeCloseEmbed = () => {
        setOpenEmbed(false);
        setIsCopiedEmbed(false);
    }
    const [inputEmbed, setInputEmbed] = React.useState({
        width: '560',
        height: '315'
    })
    React.useEffect(() => {
        setInputEmbed(prev => {
            return {
                ...prev,
                product_url: projectData.product_url
            }
        })
    }, [projectData.product_url])
    React.useEffect(() => {
        setIframe(`<iframe width=${inputEmbed.width} height=${inputEmbed.height} src="${inputEmbed.product_url}" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`)
    }, [inputEmbed])
    const handleChangeInputEmbed = (value, type) => {
        setInputEmbed(prev => {
            return {
                ...prev,
                [type]: value
            }
        });
    }
    const getEmbedModal = () => {
        return (
            <Dialog open={openEmbed} onClose={handeCloseEmbed} >
                <DialogTitle color="primary" sx={{ fontSize: '40px', textAlign: 'center', bgcolor: '#222222', color: 'white' }}>
                    Adjust Size Of Iframe
                </DialogTitle>
                <Box  >
                    <DialogContent sx={{ height: '200px', width: '500px', }}>
                        <TextField
                            defaultValue='560'
                            margin="dense"
                            id="name"
                            label="Width"
                            type="number"
                            fullWidth
                            variant="standard"
                            onChange={(e) => { handleChangeInputEmbed(e.target.value, 'width') }}
                            disabled={isCopiedEmbed === true ? true : false}
                        />
                        <TextField
                            defaultValue='315'
                            margin="dense"
                            id="describe"
                            label="Height"
                            type="number"
                            fullWidth
                            variant="standard"
                            onChange={(e) => { handleChangeInputEmbed(e.target.value, 'height') }}
                            disabled={isCopiedEmbed === true ? true : false}
                        />
                        <Typography variant="h6" align="center" sx={{ color: 'red' }} gutterBottom component="div">
                            Default : width: 560px, height : 315px
                        </Typography>
                    </DialogContent>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}>
                        <Button onClick={() => { handeCloseEmbed(); }} sx={{ width: '90px', marginBottom: '10px', bgcolor: '#222222' }} size='small' startIcon={<CancelIcon />} variant="contained" color="error">Close</Button>
                        {isCopiedEmbed === false ?
                            <Button sx={{ width: '110px', marginBottom: '10px', bgcolor: '#222222' }}
                                size='small'
                                startIcon={<SaveIcon />}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setCopy(true);
                                    setIsCopiedEmbed(true)
                                    return navigator.clipboard.writeText(iframe);
                                }}
                            >
                                Get Link
                            </Button>
                            :
                            <Button sx={{ width: '90px', marginBottom: '10px', bgcolor: '#222222' }}
                                size='small'
                                variant="contained"
                                color="primary"
                                startIcon={<CheckIcon />}

                                onClick={() => {
                                    setCopy(false);
                                    handeCloseEmbed();
                                }}
                            >
                                Done
                            </Button>
                        }
                    </Box>
                </Box>
                <Snackbar open={copy} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                        Copied !
                    </Alert>
                </Snackbar>
            </Dialog >
        )
    }
    //Pagination
    const [page, setPage] = React.useState(1);
    const PER_PAGE = 3;

    const countPage = Math.ceil(project?.length / PER_PAGE);
    const _DATA = usePagination(project, PER_PAGE);


    const handleChangePaginate = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };
    return (
        <div style={{ overflow: 'scroll', width: '100%', height: '100%' }}>
            {editInfoModal()}
            {getEmbedModal()}
            <Snackbar open={deleted} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Delete Successfully !
                </Alert>
            </Snackbar>
            <Snackbar open={editInfo} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {status}
                </Alert>
            </Snackbar>
            <Box sx={{ display: 'flex', width: '100%', height: 'fit-content', position: 'relative' }}>
                <Box sx={{
                    height: '100%',
                    width: '100%',
                }}>
                    <Paper elevation={3} sx={{
                        height: '100%',
                        bgcolor: '#e7ebf0',
                        paddingBottom: '10px'

                    }} >
                        <Box sx={{ width: '100%', height: 'fit-content', display: 'flex', justifyContent: 'center' }}>
                            <Box className="component-wrapper" pt={3} sx={{ width: '98%', marginBottom: '10px', borderRadius: '0.25rem', bgcolor: 'white', marginTop: '10px', display: 'flex' }}>
                                <Autocomplete
                                    sx={{ width: '50%', paddingBottom: '20px', height: '100%', position: 'relative', left: '24%' }}
                                    freeSolo
                                    disableClearable
                                    options={project === undefined ? noResult.map((option) => option.title) : data?.map((option) => option.title)}
                                    autoComplete={false}
                                    clearOnEscape={true}
                                    size='medium'
                                    renderInput={(params) => (
                                        <TextField
                                            onChange={(e) => { handleSearchInput(e.target.value) }}
                                            {...params}
                                            label="Search..."
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />
                                <Box sx={{ position: 'absolute', right: 35 }}>
                                    <Button
                                        href={`/user=${id.user_id}/create#describe`}
                                        variant="contained"
                                        sx={{
                                            bgcolor: '#222222',
                                            color: 'white', ':hover': { color: '#fe4066', bgcolor: '#222222' }, height: '40px', marginTop: '7px',
                                            font: 'nunito'
                                        }}>
                                        Create new 360<AddCircleOutlineIcon style={{ paddingLeft: '5px', width: '20px', height: '20px', padding: 'auto', color: '#fdfdff' }} />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', height: 'fit-content', display: 'flex', justifyContent: 'center' }}>
                            <Box className="component-wrapper" sx={{ width: '98%', display: 'flex', justifyContent: 'center', bgcolor: 'white', borderRadius: '0.25rem' }}>
                                {
                                    project?.length === 0 ?
                                        <Card sx={{ height: '100%' }} >
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <img className="img-fluid noproject" src="/images/not_available.png"></img>
                                            </Box>
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                                    No projects available !
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                                                    Click the button " Create new 360 " on the top right to start creating your 360 project !
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                        :
                                        <Box pt={3} >
                                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '10px' }} >
                                                <p style={{ margin: 0, fontSize: '34px' }}> Virtual Tour Projects</p>
                                            </Box>
                                            <Modal
                                                open={visible}
                                                onClose={handleCloseDelete}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style}>
                                                    <Typography id="modal-modal-description" align='center'>
                                                        Deleting...
                                                    </Typography>
                                                </Box>
                                            </Modal>

                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                className='list-btn'
                                            >
                                                {
                                                    _DATA.currentData()?.map((data) => {
                                                        return (
                                                            item = data?.images?.slice(0, 1),
                                                            item.map((value, index) => {
                                                                return (
                                                                    <Card sx={{
                                                                        maxWidth: 345,
                                                                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset'
                                                                    }}>
                                                                        <CardMedia
                                                                            component="img"
                                                                            height="200"
                                                                            image={value.thumbnail_url === null ? `${BASE_URL}/images/default.png` : value.thumbnail_url}
                                                                            alt="green iguana"
                                                                        />
                                                                        <CardContent>
                                                                            <Typography gutterBottom variant="h5" component="div">
                                                                                {data.title}
                                                                            </Typography>
                                                                            <Typography variant="body2" color="text.secondary">
                                                                                {data.describe}
                                                                            </Typography>
                                                                        </CardContent>
                                                                        <CardActions>
                                                                            <Stack
                                                                                direction="row"
                                                                                spacing={2}
                                                                                className='list-btn'
                                                                            >
                                                                                <Button
                                                                                    id="test"
                                                                                    variant="contained"
                                                                                    onClick={() => {
                                                                                        setProjectData({
                                                                                            id: data.id,
                                                                                            title: data.title,
                                                                                            describe: data.describe
                                                                                        })
                                                                                        setInput({
                                                                                            title: data.title,
                                                                                            describe: data.describe
                                                                                        })
                                                                                        handleOpenEditInfo()
                                                                                    }}
                                                                                >
                                                                                    <TextFormatIcon sx={{ color: '#ffffff' }} />
                                                                                </Button>
                                                                                <Button href={`/user=${id.user_id}/project=${data.id}`} variant="contained" >
                                                                                    <VisibilityIcon style={{ color: '#ffffff' }} />
                                                                                </Button>
                                                                                <Button href={`/user=${id.user_id}/editproject=${data.id}`} variant="contained">
                                                                                    <ModeEditIcon style={{ color: '#ffffff' }} />
                                                                                </Button>
                                                                                <Button variant="contained">
                                                                                    <Dropdown variant="success" >
                                                                                        <Dropdown.Toggle variant='' id='dropdown-basic' style={{ width: '60px' }}  >
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu style={{ fontSize: '13px' }}>
                                                                                            <Dropdown.Item
                                                                                                onBlur={() => { setIsCopiedSocial(false); }}
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation()
                                                                                                    setIsCopiedSocial(!isCopiedSocial);
                                                                                                    return navigator.clipboard.writeText(`${data.product_url}`);

                                                                                                }}
                                                                                            >
                                                                                                {isCopiedSocial === false ? <p style={{ margin: 0 }}><LinkedInIcon /> Get Social Link</p> : <p style={{ marginBottom: 0, marginLeft: '30%' }}>Copied <CheckIcon /></p>}                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Divider />
                                                                                            <Dropdown.Item
                                                                                                onBlur={() => { setIsCopiedEmbed(false); }}
                                                                                                onClick={(e) => {
                                                                                                    handleOpenEmbed()
                                                                                                    setProjectData({
                                                                                                        product_url: data.product_url,
                                                                                                    })
                                                                                                }}
                                                                                            >
                                                                                                {isCopiedEmbed === false ? <p style={{ margin: 0 }}><CodeIcon /> Get Embed Link</p> : <p style={{ marginBottom: 0, marginLeft: '30%' }}>Copied <CheckIcon /></p>}
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Divider />
                                                                                            <Dropdown.Item
                                                                                                onBlur={() => { setIsCopiedAPI(false); }}
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation()
                                                                                                    setIsCopiedAPI(!isCopiedAPI);
                                                                                                    return navigator.clipboard.writeText(`${data.id}`);
                                                                                                }}
                                                                                            >
                                                                                                {isCopiedAPI === false ? <p style={{ margin: 0 }}><AccountTreeIcon /> Get Project Id</p> : <p style={{ marginBottom: 0, marginLeft: '30%' }}>Copied <CheckIcon /></p>}                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Divider />
                                                                                            <Dropdown.Item
                                                                                                style={{ color: 'red' }}
                                                                                                onClick={async (e) => {
                                                                                                    setIsDeleting(!isDeleting);
                                                                                                    if (confirm('Are you sure you want delete this project?')) { // eslint-disable-line
                                                                                                        setVisible(true)
                                                                                                        await deleteProjectAPI(data.id, {
                                                                                                            headers: {
                                                                                                                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                                                                                            }
                                                                                                        });
                                                                                                        loadAllProjectAPI(id.user_id,
                                                                                                            {
                                                                                                                headers: {
                                                                                                                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                                                                                                    'Accept': 'application/json',
                                                                                                                }
                                                                                                            }
                                                                                                        ).then((res) => {
                                                                                                            setProject(res.data.data);
                                                                                                            setIsDeleting(false);
                                                                                                            setVisible(false)

                                                                                                        })
                                                                                                        setDeleted(true)
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <p style={{ margin: 0, paddingRight: '10px' }}><DeleteIcon /> Delete </p>

                                                                                            </Dropdown.Item>
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </Button>
                                                                            </Stack>
                                                                        </CardActions>
                                                                    </Card>
                                                                )
                                                            })
                                                        )
                                                    })
                                                }
                                            </Stack>
                                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '50px', paddingBottom: '19px' }}>
                                                <Pagination
                                                    size='large'
                                                    color="primary"
                                                    count={countPage}
                                                    page={page}
                                                    onChange={handleChangePaginate}
                                                />
                                            </Box>
                                        </Box>
                                }
                            </Box>
                        </Box>
                    </Paper>
                </Box >
            </Box >
            <Box sx={{ width: '100%', height: '35%', bgcolor: '#222222', justifyContent: 'center' }}>
                <footer className="w-100 py-4 flex-shrink-0 footer-wrapper">
                    <div className="container py-4 ">
                        <div className="row gy-4 gx-5">
                            <div className="col-lg-4 col-md-6">
                                <a style={{ marginLeft: '30px' }} className="" href='http://demego.vn/page/home.html'><img className="img-fluid" alt="" src={`${BASE_URL}/images/logo-footer.png`}></img></a>
                                <p className="small text-muted">TECHNOLOGY & REAL ESTATE AGENCIES</p>
                                <p className="small text-muted mb-0">WE ARE DEMEGO. <a className="text-primary" href="http://demego.vn/page/home.html">Demego.vn</a></p>
                            </div>
                            <div className="col-lg-8 col-md-6">
                                <h5 className="text-white mb-3">Contact us:</h5>
                                <ul className="list-unstyled text-muted footer-list">
                                    <li><MailOutlineOutlinedIcon /> info@demego.vn</li>
                                    <li><CallOutlinedIcon /> HOTLINE: 1900 8888</li>
                                    <li><LocationOnOutlinedIcon /> 52 Xã Đàn, P.Phương Liệt, Q.Đống Đa, Hà Nội.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
            </Box>
        </div >
    )
}
export default DashboardMain
