import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
//MUI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from "react";
import { useHistory } from "react-router-dom";
import { loginAPI, registerAPI, requestResetPasswordAPI } from "../services/Api";
import { BASE_URL } from "../services/constants/app";
// import useValidateForm from "../../hook/useValidateForm";
import "./auth.css";

const Auth = () => {
    const history = useHistory();
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (isAuthenticated) {
        history.push(`user=${localStorage.getItem('user')}`)
    }
    const [error, setError] = React.useState();
    const [validates, setValidates] = React.useState([]);
    const [openLogin, setOpenLogin] = React.useState(false);
    const [openSignUp, setOpenSignUp] = React.useState(false);
    const [openResetPassword, setOpenResetPassword] = React.useState(false);
    const [status, setStatus] = React.useState();
    const [isPending, setIsPending] = React.useState(false);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [emailReset, setEmailReset] = React.useState({
        email: ''
    });
    const [user, setUser] = React.useState({
        email: '',
        password: '',
        rememberMe: false
    });
    const [register, setRegister] = React.useState({
        name: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const handleOpenRSP = () => {
        setValidates([]);
        setOpenResetPassword(true);
    }
    const handleCloseRSP = () => {
        setValidates([]);
        setOpenResetPassword(false);
        setError()
        setStatus()
    }
    const handleOpenLogin = () => {
        setValidates([]);
        setOpenLogin(true);
    };
    const handleCloseLogin = () => {
        setError()
        setValidates([]);
        setOpenLogin(false);
    };
    const handleOpenSignUp = () => {
        setValidates([]);
        setOpenSignUp(true);
    };
    const handleCloseSignUp = () => {
        setError()
        setValidates([]);
        setOpenSignUp(false);
    };
    const handleChangeInputLogin = (value, type) => {
        setUser(prev => {
            return {
                ...prev,
                [type]: value
            }
        });
    }
    const handleChangeInputSignUp = (value, type) => {
        setRegister(prev => {
            return {
                ...prev,
                [type]: value
            }
        });
    }
    const handleChangeInputResetPassword = (value, type) => {
        setEmailReset(prev => {
            return {
                ...prev,
                [type]: value
            }
        });
    }
    const handleFormSubmitSignUp = async (e) => {
        e.preventDefault();
        setIsPending(true)
        setError();
        if (!handleValidateSignUp()) {
            return;
        }
        const name = register.name
        const email = register.email;
        const phone = register.phone;
        const password = register.password;

        await registerAPI({
            'name': name,
            'phone': phone,
            'email': email,
            'password': password
        }).then((res) => {
            if (res.data.status === 'success') {
                loginAPI(
                    {
                        'email': res.data.email,
                        'password': res.data.password
                    }).then((res) => {
                        setIsPending(false)
                        localStorage.setItem('token', res.data.access_token);
                        localStorage.setItem('isAuthenticated', true, 1000)
                        localStorage.setItem('user', res.data.apikey)
                        history.push(`/user=${res.data.apikey}`)
                    })
            } else {
                setError(res.data.message);
                setIsPending(false)
            }
        })
    }
    const handleFormSubmitLogin = async (e) => {
        e.preventDefault();
        setIsPending(true)
        setError()
        if (!handleValidateLogin()) {
            return;
        }
        const email = user.email;
        const password = user.password;
        const rememberMe = user.rememberMe;
        localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
        await loginAPI(
            {
                'email': email,
                'password': password
            }).then((res) => {
                if (res?.data?.status === 'success') {
                    setIsPending(false)
                    localStorage.setItem('token', res.data.access_token);
                    localStorage.setItem('isAuthenticated', true, 1000)
                    localStorage.setItem('user', res.data.apikey)
                    history.push(`/user=${res.data.apikey}`);
                }
                else {
                    setError(res.data.message);
                    setIsPending(false)
                }
            })
    }
    const handleFromSubmitResetPassword = async (e) => {
        e.preventDefault();
        if (!handleValidateResetPassword()) {
            return;
        }
        setError()
        setIsPending(true)
        const email = emailReset.email
        await requestResetPasswordAPI({
            'email': email
        }).then((res) => {
            if (res?.data?.status === 'success') {
                setStatus(res.data.message);
                setIsPending(false)
            }
            else {
                setError(res.data.message)
                setIsPending(false)
            }
        })
    }
    const handleValidateLogin = () => {
        setValidates([]);
        let isValid = true;
        if (user.email === '') {
            setValidates(prev => ([...prev, { email: ['Email is required'] }]));
            isValid = false;
        }
        if (user.password === '') {
            setValidates(prev => ([...prev, { password: ['Password is required'] }]));
            isValid = false;
        }
        return isValid;
    }
    const handleValidateSignUp = () => {
        setValidates([]);
        let isValid = true;
        if (register.name === '') {
            setValidates(prev => ([...prev, { name: ['Name is required'] }]));
            isValid = false;
        }
        if (register.email === '') {
            setValidates(prev => ([...prev, { suemail: ['Email is required'] }]));
            isValid = false;
        }
        if (register.password === '') {
            setValidates(prev => ([...prev, { supass: ['Password is required'] }]));
            isValid = false;
        }
        if (register.password !== register.confirmPassword) {
            setValidates(prev => ([...prev, { match: ['Password does not match'] }]));
            isValid = false;
        }
        return isValid;
    }
    const handleValidateResetPassword = () => {
        setValidates([]);
        let isValid = true;
        if (emailReset.email === '') {
            setValidates(prev => ([...prev, { email: ['Email is required'] }]));
            isValid = false;
        }
        return isValid;

    }
    const getErrorMessage = type => {
        const currentError = validates.find(validate => validate[type]);
        if (typeof currentError === 'undefined') {
            return [];
        }
        return currentError[type];
    }
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <div className='auth-wrapper'>
            <div id="header">
                <AppBar position="static" sx={{ height: '80px', display: 'block', backgroundImage: 'linear-gradient(147deg, #000000 0%, #434343 74%)' }}>
                    <Toolbar sx={{ height: '80px' }}>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <MenuItem onClick={handleCloseNavMenu} right="right" onClick={() => { window.open('https://github.com/MHunga/amz_360/blob/1.0.4/README.md') }}>
                                    <Typography
                                        textAlign="center">Mobile SDK</Typography>
                                </MenuItem>
                                <MenuItem onClick={handleCloseNavMenu} right="right" onClick={() => { window.open('http://demego.vn/page/about-us.html') }} >
                                    <Typography textAlign="center">Contact</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                        <Box>
                            <img style={{ height: '80px' }} alt='' className="img-fluid" src={`${BASE_URL}/images/logo.png`}></img>
                        </Box>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, position: "relative", justifyContent: "right", right: "10%" }}>
                                <Button
                                    variant="text"
                                    id="basic-button"
                                    aria-controls="basic-menu"
                                    aria-haspopup="true"
                                    onClick={() => { window.open('https://github.com/MHunga/amz_360/blob/1.0.4/README.md') }}
                                >
                                    <p id="basic-button-text" style={{ margin: '0' }}>Mobile SDK</p>
                                </Button>
                                <Button
                                    variant="text"
                                    id="basic-button"
                                    aria-controls="basic-menu"
                                    aria-haspopup="true"
                                    onClick={() => { window.open('http://demego.vn/page/about-us.html') }}
                                >
                                    <p id="basic-button-text" style={{ margin: '0' }}>Contact</p>
                                </Button>
                            </Box>
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Button onClick={() => { handleOpenLogin() }} size="large" sx={{ marginRight: '15px', color: 'white', background: 'linear-gradient(147deg, #000000 0%, #434343 74%)', ':hover': { bgcolor: '#c62828' } }} variant="contained">Sign In</Button>
                            <Button onClick={() => { handleOpenSignUp() }} size="large" sx={{ color: 'white', background: 'linear-gradient(147deg, #000000 0%, #434343 74%)' }} color='error' variant="contained">Register</Button>
                        </Box>
                    </Toolbar>
                </AppBar>
            </div >
            <div className="body" >
                <div className="container">
                    <Box mt={2} className="des">
                        <h3>VIRTUAL TOUR SYSTEM</h3>
                        <h4>This system helps you create projects with vivid, multi-dimensional 360 images, full of information and easy to understand for your customers.</h4>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Stack
                            direction="row"
                            spacing={2}
                        >
                            <Card sx={{ minWidth: 400, maxWidth: 275 }}>
                                <CardContent>
                                    <Typography component="div" align='center'>
                                        <PeopleIcon sx={{ width: '70px', height: '70px' }} />
                                    </Typography>
                                    <Typography variant="h5" component="div" align='center'>
                                        Win more listings
                                    </Typography>
                                    <Typography variant="body2" mt={2}>
                                        Our customers confirm that using 360 virtual tours helps them win every listing against their competitors.
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ minWidth: 400, maxWidth: 275 }}>
                                <CardContent>
                                    <Typography component="div" align='center'>
                                        <VisibilityRoundedIcon sx={{ width: '70px', height: '70px' }} />
                                    </Typography>
                                    <Typography variant="h5" component="div" align='center'>
                                        Rent or Sell sight unseen
                                    </Typography>
                                    <Typography variant="body2" mt={2}>
                                        Open House 24/7. Remove property access issues. Listings with 360 tours have 497% more engagement than listings with videos.                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ minWidth: 400, maxWidth: 275 }}>
                                <CardContent>
                                    <Typography component="div" align='center'>
                                        <EmojiEventsRoundedIcon sx={{ width: '70px', height: '70px' }} />
                                    </Typography>
                                    <Typography variant="h5" component="div" align='center'>
                                        Stand out from the competition
                                    </Typography>
                                    <Typography variant="body2" mt={2}>
                                        Be the innovative agent and beat the competition hands down. Reduce time on market by up to 31%
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Stack>
                    </Box>
                </div>
                <Box sx={{ bgcolor: '#222222' }} >
                    <Typography variant="h4" component="div" align='center' color="white" sx={{ paddingTop: '20px', paddingBottom: '20px' }}>
                        See a live tour in action
                    </Typography>
                    <div id='engine' style={{ width: '100%', height: '600px' }}>
                        <iframe width="1296" height="550" src="https://www.modernbiztech.com/embed/project=123?apikey=1p7g4RrCOpQM2ze4rki1j4KvK" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                        </div>
                </Box>
                <Box>
                    <Typography variant="h4" component="div" align='center' sx={{ paddingTop: '55px', paddingBottom: '55px' }}>
                        <Button sx={{
                            borderRadius: '2.5em',
                            height: '50px',
                            width: '500px',
                            color: '#222222',
                            bgcolor: '#FFD700',
                            '&:hover': {
                                transition: '350ms',
                                backgroundImage: 'radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,209,67,1) 0%, rgba(255,145,83,1) 90% )'
                            }
                        }}
                            onClick={() => { handleOpenSignUp() }}
                        >
                            It’s easy to use and free! Register now
                        </Button>
                    </Typography>
                </Box>
            </div >
            <div id='footer' >
                <Box sx={{ width: '100%', bgcolor: '#222222', justifyContent: 'center' }}>
                    <footer className="w-100  flex-shrink-0 footer-wrapper">
                        <div className="container py-4 ">
                            <div className="row gy-4 gx-5">
                                <div className="col-lg-4 col-md-6">
                                    <a style={{ marginLeft: '30px' }} className="" href='http://demego.vn/page/home.html'><img className="img-fluid" alt="" src={`${BASE_URL}/images/logo-footer.png`}></img></a>
                                    <p className="small text-muted">TECHNOLOGY & REAL ESTATE AGENCIES</p>
                                    <p className="small text-muted mb-0">WE ARE DEMEGO. <a className="text-primary" href="http://demego.vn/page/home.html">Demego.vn</a></p>
                                </div>
                                <div className="col-lg-8 col-md-6">
                                    <h5 className="text-white mb-3">Contact us:</h5>
                                    <ul className="list-unstyled text-muted footer-list">
                                        <li><MailOutlineOutlinedIcon /> info@demego.vn</li>
                                        <li><CallOutlinedIcon /> HOTLINE: 1900 8888</li>
                                        <li><LocationOnOutlinedIcon /> 52 Xã Đàn, P.Phương Liệt, Q.Đống Đa, Hà Nội.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </footer>
                </Box>
            </div>
            {/* Login */}
            <Dialog open={openLogin} onClose={handleCloseLogin}  >
                <DialogTitle color="primary" sx={{ fontSize: '40px', textAlign: 'center', color: 'white', bgcolor: '#222222' }}>Login</DialogTitle>
                <Box className={`${isPending === true ? 'pending' : ''}`} >
                    <CircularProgress className={`${isPending === true ? 'pending-icon__active' : 'pending-icon__inactive'}`} />
                    <DialogContent sx={{ height: '300px', width: '500px', }}>
                        <TextField
                            error={getErrorMessage('email').length > 0 ? true : false}
                            helperText={getErrorMessage('email').length > 0 ? getErrorMessage('email').map(message => {
                                return message
                            }) : ''}
                            required
                            margin="normal"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            onChange={e => handleChangeInputLogin(e.target.value, 'email')}
                        />
                        <TextField
                            error={getErrorMessage('password').length > 0 ? true : false}
                            helperText={getErrorMessage('password').length > 0 ? getErrorMessage('password').map(message => {
                                return message
                            }) : ''}
                            required
                            margin="normal"
                            id="password"
                            label="Your Password"
                            type="password"
                            fullWidth
                            variant="standard"
                            onChange={e => handleChangeInputLogin(e.target.value, 'password')}
                        />
                        {/* <FormControlLabel sx={{position:'relative'}} control={<Checkbox />} label="Stay Logged In" /> */}
                        <Typography mt={1} onClick={() => { handleCloseLogin(); handleOpenSignUp() }} sx={{ color: '#222222', fontSize: '20px' }} color="primary" ><a id='toSignup'>Don't have an account? Sign Up</a></Typography >
                        <Typography mt={1} onClick={() => { handleCloseLogin(); handleOpenRSP() }} sx={{ color: '#fe4066', fontSize: '20px' }} color="primary" ><a id='toSignup'>Forgot password ?</a></Typography >
                        {error &&
                            <Typography mt={1} color="error" sx={{ fontSize: '20px' }} >
                                {error}
                            </Typography >
                        }
                    </DialogContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}>
                        <Button onClick={() => { handleCloseLogin() }} sx={{ color: '#ffffff', width: '90px', marginBottom: '10px', bgcolor: '#222222' }} size='small' variant="contained" color="error">Cancel</Button>
                        <Button sx={{ width: '90px', marginBottom: '10px', color: '#ffffff', bgcolor: '#222222' }}
                            size='medium'
                            variant="contained"
                            color="primary"
                            type="submit"
                            className='submit'
                            onClick={(e) =>
                                handleFormSubmitLogin(e)
                            }
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            {/* Sign-up */}
            <Dialog open={openSignUp} onClose={handleCloseSignUp} >
                <DialogTitle color="primary" sx={{ fontSize: '40px', textAlign: 'center', color: 'white', bgcolor: '#222222' }}>Sign Up</DialogTitle>
                <Box className={`${isPending === true ? 'pending' : ''}`} >
                    <CircularProgress className={`${isPending === true ? 'pending-icon__active' : 'pending-icon__inactive'}`} />
                    <DialogContent sx={{ height: '450px', width: '500px', }}>
                        <TextField
                            error={getErrorMessage('name').length > 0 ? true : false}
                            helperText={getErrorMessage('name').length > 0 ? getErrorMessage('name').map(message => {
                                return message
                            }) : ''}
                            required
                            margin="normal"
                            id="name"
                            label="Full Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={e => handleChangeInputSignUp(e.target.value, 'name')}
                        />

                        <TextField
                            error={getErrorMessage('suemail').length > 0 ? true : false}
                            helperText={getErrorMessage('suemail').length > 0 ? getErrorMessage('suemail').map(message => {
                                return message
                            }) : ''}
                            required
                            margin="normal"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            onChange={e => handleChangeInputSignUp(e.target.value, 'email')}
                        />

                        <TextField

                            margin="normal"
                            id="name"
                            label="Phone Number"
                            type="number"
                            fullWidth
                            variant="standard"
                            onChange={e => handleChangeInputSignUp(e.target.value, 'phone')}

                        />
                        <TextField
                            error={getErrorMessage('supass').length > 0 ? true : false}
                            helperText={getErrorMessage('supass').length > 0 ? getErrorMessage('supass').map(message => {
                                return message
                            }) : ''}
                            required
                            margin="normal"
                            id="name"
                            label="Password"
                            type="password"
                            fullWidth
                            variant="standard"
                            onChange={e => handleChangeInputSignUp(e.target.value, 'password')}
                        />

                        <TextField
                            error={getErrorMessage('match').length > 0 ? true : false}
                            helperText={getErrorMessage('match').length > 0 ? getErrorMessage('match').map(message => {
                                return message
                            }) : ''}
                            required
                            margin="normal"
                            id="name"
                            label="Confirm Password"
                            type="password"
                            fullWidth
                            variant="standard"
                            onChange={e => handleChangeInputSignUp(e.target.value, 'confirmPassword')}
                        />
                        {error &&
                            <Typography mt={1} color="error" sx={{ fontSize: '20px' }} >
                                {error}
                            </Typography >
                        }
                    </DialogContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}>
                        <Button onClick={() => { handleCloseSignUp() }} sx={{ width: '90px', marginBottom: '10px', bgcolor: '#222222' }} size='small' variant="contained" color="error">Cancel</Button>
                        <Button sx={{ width: '90px', marginBottom: '10px', bgcolor: '#222222' }}
                            size='medium'
                            variant="contained"
                            color="primary"
                            type='submit'
                            className='submit'
                            onClick={(e) =>
                                handleFormSubmitSignUp(e)
                            }
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            {/* Reset Password */}
            <Dialog open={openResetPassword} onClose={handleCloseRSP} >
                <DialogTitle color="primary" sx={{ fontSize: '40px', textAlign: 'center', bgcolor: '#222222', color: 'white' }}>Reset Password</DialogTitle>
                <Box className={`${isPending === true ? 'pending' : ''}`} >
                    <CircularProgress className={`${isPending === true ? 'pending-icon__active' : 'pending-icon__inactive'}`} />
                    <DialogContent sx={{ height: '200px', width: '500px', }}>
                        <DialogContentText mt={2}>
                            Enter your email and we'll send you an email to get back into your account.
                        </DialogContentText>
                        <TextField
                            error={getErrorMessage('email').length > 0 ? true : false}
                            helperText={getErrorMessage('email').length > 0 ? getErrorMessage('email').map(message => {
                                return message
                            }) : ''}
                            required
                            margin="normal"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            onChange={e => handleChangeInputResetPassword(e.target.value, 'email')}
                        />
                        {error &&
                            <Typography mt={1} color="error" sx={{ fontSize: '20px' }} >
                                {error}
                            </Typography >
                        }
                        {status &&
                            <Typography mt={1} color='#222222' sx={{ fontSize: '20px' }} >
                                {status}
                            </Typography >
                        }
                    </DialogContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}>
                        <Button onClick={() => { handleCloseRSP() }} sx={{ width: '90px', marginBottom: '10px', bgcolor: '#222222' }} size='small' variant="contained" color="error">Cancel</Button>
                        <Button sx={{ width: '90px', marginBottom: '10px', bgcolor: '#222222' }}
                            size='medium'
                            variant="contained"
                            color="primary"
                            type="submit"
                            className='submit'
                            onClick={(e) =>
                                handleFromSubmitResetPassword(e)
                            }
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </div >
    )
}
export default Auth
