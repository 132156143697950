import React from 'react';
import { DropzoneArea } from '@pandemicode/material-ui-dropzone'
import { uploadProjectAPI } from "../../../services/Api"
import { ProgressBar } from 'react-bootstrap';
import ReactLoading from "react-loading";
import "./Step2.css"
import { useHistory, useParams } from 'react-router-dom';
import HeaderDashboard from '../../../pages/dashboard/header'
import Typography from '@mui/material/Typography';

const Step2 = (props) => {
    const [d, setValidates] = React.useState([]);
    let file = [];
    const [percent, setPercent] = React.useState(0);
    const [isUploading, setIsUploading] = React.useState(false);
    let data = new FormData();
    const id = useParams();
    const history = useHistory();
    const handleChangeInput = (files) => {
        file = [...file, files]
    }
    const handeDelete = (files) => {
        const y = file.flat().filter(x => x.name !== files.name);
        file = y;
    }
    const create = async () => {
        if (!handleValidate()) {
            return;
        }
        setIsUploading(!isUploading)
        file.flat().map((value) => {
            return data.append('img[]', value)
        })
        data.append('title', props.title);
        data.append('describe', props.describe);
        data.append('user_id', id.user_id);
        const options = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let uploadPercent = Math.floor((loaded * 100) / total);
                setPercent(uploadPercent)
            }
        }
        await uploadProjectAPI(data, options).then((res) => {
            if (res.data.status === 'fail') {
                if (confirm(`${res.data.message}`)) { // eslint-disable-line
                    history.push(`/user=${id.user_id}`)
                } else {
                    history.push(`/user=${id.user_id}`)
                }
            } else {
                history.push(`/user=${id.user_id}/editproject=${res.data.data.id}`)
            }
        })
        data.delete('title');
        data.delete('describe');
        data.delete('user_id');
        data.delete('img[]')
    }
    const handleValidate = () => {
        setValidates([]);
        let isValid = true;
        if (file.length === 0) {
            setValidates(prev => ([...prev, { file: ['Project require at least 1 image !'] }]));
            isValid = false;
        }
        return isValid;
    }
    const validate = () => {
        if (confirm('Are you sure you want to go back?')) { // eslint-disable-line
            props.previousStep();
        }
    };
    const getErrorMessage = type => {
        const currentError = d.find(validate => validate[type]);
        if (typeof currentError === 'undefined') {
            return [];
        }
        return currentError[type];
    }
    return (
        <>
            <>
                <HeaderDashboard />
                <div className='container-fluid'>
                    <div className=" upload-image col-lg-8 col-md-8 col-sm-12 con-xs-12 hide">
                        {
                            percent === 100 ?
                                <div className='creating'>
                                    <strong>Creating Project...</strong>
                                    <br />
                                    <ReactLoading height='200px' width='200px' type={'spinningBubbles'} color="#1976D2" />
                                </div>
                                :
                                <>
                                    <DropzoneArea filesLimit={9}
                                        maxFileSize={41943040}
                                        acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                                        onDrop={files => { handleChangeInput(files); }}
                                        onDelete={files => { handeDelete(files); }}
                                    />
                                    {percent > 0 && <ProgressBar animated now={percent} label={`Uploading Image : ${percent}%`} />}
                                    <Typography align='center' variant="h5" gutterBottom component="div" sx={{ paddingTop: '15px', color: 'red' }}>
                                        {
                                            getErrorMessage('file').map(message => {
                                                return message
                                            })
                                        }
                                    </Typography>
                                </>
                        }

                        {isUploading === true ? '' :
                            <div className='step-button'>
                                <button style={{
                                    backgroundColor: '#222222', border: '#222222'
                                }} className="btn btn-danger btn-block" onClick={props.previousStep}>Back</button>
                                <button style={{
                                    backgroundColor: '#222222', border: '#222222'
                                }} type="submit" className="btn btn-primary btn-block" onClick={() => {
                                    create();
                                }} >Start</button>
                            </div>
                        }
                    </div>


                </div>
            </>



        </>
    )
}
export default Step2
