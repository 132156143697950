import axios from "axios";
import { BASE_API } from "./constants/app";

const Http = axios.create({
    baseURL: BASE_API,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',

    }
});

export default Http;
