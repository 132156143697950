import React from "react";
import { Redirect, Route } from "react-router-dom";
const ProtectRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? <Component {...props} /> : <Redirect to="/sign-in" />
            }
        />
    )
}
export default ProtectRoute