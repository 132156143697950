import React from 'react';
import HeaderDashboard from "../../pages/dashboard/header";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import "./ApiList.css"
import { BASE_URL } from '../../services/constants/app';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';

const ApiList = () => {
    const id = useParams();
    const [copy, setCopy] = React.useState(false);
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setCopy(false);
    };
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    return (
        <>
            <Snackbar open={copy} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Copied !
                </Alert>
            </Snackbar>
            <HeaderDashboard />
            <Box sx={{ bgcolor: '#f5f4f3', height: '100vh', overflow: 'scroll' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', height: '60%' }}>
                    <Paper evelation={3} variant="outlined" square sx={{ width: '80%', height: '100%' }} >
                        <Divider width="80%" sx={{ marginBottom: '15px', marginLeft: '120px' }} />
                        <div className='api-wrapper-main' style={{ marginTop: '5%' }}>
                            <table style={{ width: 'fit-content' }}>
                                <tr>
                                    <th >
                                        <p align='center' style={{ width: '100%', margin: '0', fontSize: '22px' }}>
                                            API Link
                                        </p>
                                    </th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td>
                                        <p className='apilist-list'><span className='apilist-span'> I. Create:</span> https://api.modernbiztech.com/api/v1/app/project/create?apikey=<span className='apilist-span'>{id.user_id}</span> </p>
                                    </td>
                                    <td>
                                        <Button
                                            className='copy-button'
                                            onClick={() => {
                                                setCopy(true)
                                                return navigator.clipboard.writeText(`https://api.modernbiztech.com/api/v1/app/project/create?apikey=${id.user_id}`);
                                            }} sx={{ marginLeft: '10px', height: '36px', bgcolor: '#222222', color: 'white', ':hover': { bgcolor: '#222222' } }} variant="contained">
                                            Copy
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className='apilist-list'><span className='apilist-span'>II. Read:</span>https://api.modernbiztech.com/api/v1/app/project/<span className='apilist-span'>*project_id*</span>?apikey=<span className='apilist-span'>{id.user_id}</span> </p>
                                    </td>
                                    <td>
                                        <Button
                                            className='copy-button'
                                            onClick={() => {
                                                setCopy(true)
                                                return navigator.clipboard.writeText(`https://api.modernbiztech.com/api/v1/app/project/*project_id*?apikey=${id.user_id}`);
                                            }} sx={{ marginLeft: '10px', height: '36px', bgcolor: '#222222', color: 'white', ':hover': { bgcolor: '#222222' } }} variant="contained">Copy</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className='apilist-list'><span className='apilist-span'>III. Update:</span> https://api.modernbiztech.com/api/v1/project/update/<span className='apilist-span'>*project_id*</span>?apikey=<span className='apilist-span'>{id.user_id}</span> </p>
                                    </td>
                                    <td>
                                        <Button
                                            className='copy-button'
                                            onClick={() => {
                                                setCopy(true)
                                                return navigator.clipboard.writeText(`https://api.modernbiztech.com/api/v1/app/project/update/*project_id*?apikey=${id.user_id}`);
                                            }} sx={{ marginLeft: '10px', height: '36px', bgcolor: '#222222', color: 'white', ':hover': { bgcolor: '#222222' } }} variant="contained">Copy</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className='apilist-list'><span className='apilist-span'>IV. Delete:</span>https://api.modernbiztech.com/api/v1/app/project/delete/<span className='apilist-span'>*project_id*</span>?apikey=<span className='apilist-span'>{id.user_id}</span> </p>
                                    </td>
                                    <td>
                                        <Button
                                            className='copy-button'
                                            onClick={() => {
                                                setCopy(true)
                                                return navigator.clipboard.writeText(`https://api.modernbiztech.com/api/v1/app/project/delete/*project_id*?apikey=${id.user_id}`);
                                            }} sx={{ marginLeft: '10px', height: '36px', bgcolor: '#222222', color: 'white', ':hover': { bgcolor: '#222222' } }} variant="contained">Copy</Button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className='api-wrapper-main' style={{ marginTop: '20px' }}>
                            <strong style={{ fontSize: '20px' }}>
                                *NOTE : Project_id will be provided once creating a project successfully or getting directly from existing project on dashboard
                            </strong>
                        </div>
                    </Paper>
                </Box>
                <Box sx={{ width: '100%', height: '35%', bgcolor: '#222222', justifyContent: 'center' }}>
                    <footer className="w-100 py-4 flex-shrink-0 footer-wrapper">
                        <div className="container py-4 ">
                            <div className="row gy-4 gx-5">
                                <div className="col-lg-4 col-md-6">
                                    <a style={{ marginLeft: '30px' }} className="" href='http://demego.vn/page/home.html'><img className="img-fluid" alt="" src={`${BASE_URL}/images/logo-footer.png`}></img></a>
                                    <p className="small text-muted">TECHNOLOGY & REAL ESTATE AGENCIES</p>
                                    <p className="small text-muted mb-0">WE ARE DEMEGO. <a className="text-primary" href="http://demego.vn/page/home.html">Demego.vn</a></p>
                                </div>
                                <div className="col-lg-8 col-md-6">
                                    <h5 className="text-white mb-3">Contact us:</h5>
                                    <ul className="list-unstyled text-muted footer-list">
                                        <li><MailOutlineOutlinedIcon /> info@demego.vn</li>
                                        <li><CallOutlinedIcon /> HOTLINE: 1900 8888</li>
                                        <li><LocationOnOutlinedIcon /> 52 Xã Đàn, P.Phương Liệt, Q.Đống Đa, Hà Nội.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </footer>
                </Box>
            </Box>
        </>
    )
}
export default ApiList

 // <>
        //     <Snackbar open={copy} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        //         <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
        //             Copied !
        //         </Alert>
        //     </Snackbar>
        //     <HeaderDashboard />
        //     <Box sx={{ bgcolor: '#f5f4f3', height: '100vh', overflow: 'scroll' }}>
        //         <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        //             <Paper evelation={3} variant="outlined" square sx={{ width: '80%', height: 'fit-content' }} >
        //                 <h3 style={{ marginTop: '15px' }}>Api List</h3>
        //                 <Divider width="80%" sx={{ marginBottom: '15px', marginLeft: '120px' }} />
        //                 {/* <strong className='apilist-title'>1. Get Apikey</strong>
        //                 <p className='apilist-body'> - Để sử dụng API cần có Apikey, lấy Apikey bằng cách click vào biểu tượng User sau đó chọn Get Api Key:</p>
        //                 <img style={{ width: '80%' }} className='img-fluid apilist-body' src={`${BASE_URL}/images/apikey-get.png`}></img> */}
        //                 <br />
        //                 {/* <Divider width="80%" sx={{ marginBottom: '15px', marginLeft: '120px' }} /> */}
        //                 {/* <strong className='apilist-title'>API List:</strong> */}
        //                 <p className='apilist-list'><span className='apilist-span'> I. Create:</span> </p>
        //                 <div className='api-wrapper-main'>
        //                     <div className='api-wrapper' title="Click here to copy API">
        //                         <p className='apilist-list api-copy'>https://api.modernbiztech.com/api/v1/app/project/create?apikey=<span className='apilist-span'>{id.user_id}</span> </p>
        //                         <Button
        //                             className='copy-button'
        //                             onClick={() => {
        //                                 setCopy(true)
        //                                 return navigator.clipboard.writeText(`https://api.modernbiztech.com/api/v1/app/project/create?apikey=${id.user_id}`);
        //                             }} sx={{ marginLeft: '10px', height: '36px', bgcolor: '#222222', color: 'white', ':hover': { bgcolor: '#222222' } }} variant="contained">
        //                             Copy</Button>
        //                     </div>
        //                 </div>
        //                 {/* <p className='apilist-body'>- Method : POST</p>
        //                 <p className='apilist-body'>- Header : Content-Type': multipart/form-data; boundary=&lt;calculated when request is sent&gt;"</p>
        //                 <p className='apilist-body'>- Body : &#123; <br /></p>
        //                 <p className='apilist-api'>title : Tiêu đề<br /></p>
        //                 <p className='apilist-api'>describe: Mô tả Project<br /></p>
        //                 <p className='apilist-api'>img[]: List file ảnh<br /></p>
        //                 <p className='apilist-body'>&#125;<br /></p>
        //                 <p className='apilist-body'>- Response: &#123;<br /></p>
        //                 <p className='apilist-api'>status : success/fail<br /></p>
        //                 <p className='apilist-api'>message : ""<br /></p>
        //                 <p className='apilist-api'>data  : &#123;<br /></p>
        //                 <p className='apilist-api2'>id : ""<br /></p>
        //                 <p className='apilist-api2'>title : ""<br /></p>
        //                 <p className='apilist-api2'>description : ""<br /></p>
        //                 <p className='apilist-api2'>author : ""<br /></p>
        //                 <p className='apilist-api2'>images : [<br /></p>
        //                 <p className='apilist-api3'> &#123;<br /></p>
        //                 <p className='apilist-api4'>image:  &#123; <br /></p>
        //                 <p className='apilist-api4'>id: "" <br /></p>
        //                 <p className='apilist-api4'>url: "" <br /></p>
        //                 <p className='apilist-api4'>thumbnail_url: "" <br /></p>
        //                 <p className='apilist-api4'> &#125; <br /></p>
        //                 <p className='apilist-api3'> &#125;<br /></p>
        //                 <p className='apilist-api2'>]<br /></p>
        //                 <p className='apilist-api'>&#125;<br /></p>
        //                 <p className='apilist-body'>&#125;<br /></p> */}
        //                 <br />
        //                 <p className='apilist-list'><span className='apilist-span'>II. Read:</span> </p>
        //                 <div className='api-wrapper-main'>
        //                     <div className='api-wrapper' title="Click here to copy API">
        //                         <p className='apilist-list api-copy'>https://api.modernbiztech.com/api/v1/app/project/<span className='apilist-span'>*project_id*</span>?apikey=<span className='apilist-span'>{id.user_id}</span>
        //                         </p>
        //                         <Button
        //                             className='copy-button'
        //                             onClick={() => {
        //                                 setCopy(true)
        //                                 return navigator.clipboard.writeText(`https://api.modernbiztech.com/api/v1/app/project/*project_id*?apikey=${id.user_id}`);
        //                             }} sx={{ marginLeft: '10px', height: '36px', bgcolor: '#222222', color: 'white', ':hover': { bgcolor: '#222222' } }} variant="contained">Copy</Button>
        //                     </div>
        //                 </div>
        //                 {/* <p className='apilist-body'>- Method : GET</p>
        //                 <p className='apilist-body'>- Header : ""</p>
        //                 <p className='apilist-body'>- Response: &#123;<br /></p>
        //                 <p className='apilist-api'>status : success/fail<br /></p>
        //                 <p className='apilist-api'>message : ""<br /></p>
        //                 <p className='apilist-api'>data  : &#123;<br /></p>
        //                 <p className='apilist-api2'>id : ""<br /></p>
        //                 <p className='apilist-api2'>title : ""<br /></p>
        //                 <p className='apilist-api2'>description : ""<br /></p>
        //                 <p className='apilist-api2'>author : ""<br /></p>
        //                 <p className='apilist-api2'>images : [<br /></p>
        //                 <p className='apilist-api3'> &#123;<br /></p>
        //                 <p className='apilist-api4'>image:  &#123; <br /></p>
        //                 <p className='apilist-api5'>id: "" <br /></p>
        //                 <p className='apilist-api5'>url: "" <br /></p>
        //                 <p className='apilist-api5'>thumbnail_url: "" <br /></p>
        //                 <p className='apilist-api4'> &#125; <br /></p>
        //                 <p className='apilist-api4'>label:  [ <br /></p>
        //                 <p className='apilist-api5'> &#123;<br /></p>
        //                 <p className='apilist-api5'> id: ""<br /></p>
        //                 <p className='apilist-api5'> title: ""<br /></p>
        //                 <p className='apilist-api5'> text: ""<br /></p>
        //                 <p className='apilist-api5'> image_url: ""<br /></p>
        //                 <p className='apilist-api5'> video_url: ""<br /></p>
        //                 <p className='apilist-api5'> image_id: ""<br /></p>
        //                 <p className='apilist-api5'> positions:<br /></p>
        //                 <p className='apilist-api6'> &#123;<br /></p>
        //                 <p className='apilist-api6'> x: ""<br /></p>
        //                 <p className='apilist-api6'> y: ""<br /></p>
        //                 <p className='apilist-api6'> z: ""<br /></p>
        //                 <p className='apilist-api6'> &#125;<br /></p>
        //                 <p className='apilist-api5'> &#125;<br /></p>
        //                 <p className='apilist-api4'> ] <br /></p>
        //                 <p className='apilist-api4'>link:  [ <br /></p>
        //                 <p className='apilist-api5'> &#123;<br /></p>
        //                 <p className='apilist-api5'> id: ""<br /></p>
        //                 <p className='apilist-api5'> positions:<br /></p>
        //                 <p className='apilist-api6'> &#123;<br /></p>
        //                 <p className='apilist-api6'> x: ""<br /></p>
        //                 <p className='apilist-api6'> y: ""<br /></p>
        //                 <p className='apilist-api6'> z: ""<br /></p>
        //                 <p className='apilist-api6'> &#125;<br /></p>
        //                 <p className='apilist-api5'> &#125;<br /></p>
        //                 <p className='apilist-api5'> to_image: ""<br /></p>
        //                 <p className='apilist-api4'> ] <br /></p>
        //                 <p className='apilist-api3'> &#125;<br /></p>
        //                 <p className='apilist-api2'>]<br /></p>
        //                 <p className='apilist-api'>&#125;<br /></p>
        //                 <p className='apilist-body'>&#125;<br /></p> */}
        //                 <br />
        //                 <p className='apilist-list'><span className='apilist-span'>III. Update:</span></p>
        //                 <div className='api-wrapper-main'>
        //                     <div className='api-wrapper' title="Click here to copy API">
        //                         <p className='apilist-list api-copy'> https://api.modernbiztech.com/api/v1/project/update/<span className='apilist-span'>*project_id*</span>?apikey=<span className='apilist-span'>{id.user_id}</span> </p>
        //                         <Button
        //                             className='copy-button'
        //                             onClick={() => {
        //                                 setCopy(true)
        //                                 return navigator.clipboard.writeText(`https://api.modernbiztech.com/api/v1/app/project/update/*project_id*?apikey=${id.user_id}`);
        //                             }} sx={{ marginLeft: '10px', height: '36px', bgcolor: '#222222', color: 'white', ':hover': { bgcolor: '#222222' } }} variant="contained">Copy</Button>
        //                     </div>
        //                 </div>
        //                 {/* <p className='apilist-body'>- Method : POST</p>
        //                 <p className='apilist-body'>- Header : Content-Type': multipart/form-data; boundary=&lt;calculated when request is sent&gt;"</p>
        //                 <p className='apilist-body'>- Body : &#123; <br /></p>
        //                 <p className='apilist-api'>action : "edit_infomation/add_image/remove_image"<br /></p>
        //                 <p className='apilist-api'>title : ""<br /></p>
        //                 <p className='apilist-api'>description : ""<br /></p>
        //                 <p className='apilist-api'>img[] : ""(file ảnh thêm vào project)<br /></p>
        //                 <p className='apilist-api'>image_id : ""(id ảnh xóa khỏi project)<br /></p>
        //                 <p className='apilist-body'>&#125; <br /></p>
        //                 <p className='apilist-body'>- Response: &#123;<br /></p>
        //                 <p className='apilist-api'>status : success/fail<br /></p>
        //                 <p className='apilist-api'>message : ""<br /></p>
        //                 <p className='apilist-api'>data : &#123;<br /></p>
        //                 <p className='apilist-api2'>id : ""<br /></p>
        //                 <p className='apilist-api2'>title : ""<br /></p>
        //                 <p className='apilist-api2'>slug : ""<br /></p>
        //                 <p className='apilist-api2'>describe : ""<br /></p>
        //                 <p className='apilist-api2'>image_list : []<br /></p>
        //                 <p className='apilist-api2'>owner_id : ""<br /></p>
        //                 <p className='apilist-api2'>product_url : ""<br /></p>
        //                 <p className='apilist-api2'>created_at : ""<br /></p>
        //                 <p className='apilist-api2'>updated_at : ""<br /></p>
        //                 <p className='apilist-api'>&#125;<br /></p>
        //                 <p className='apilist-body'>&#125; <br /></p> */}
        //                 <br />
        //                 <p className='apilist-list'><span className='apilist-span'>IV. Delete:</span> </p>
        //                 <div className='api-wrapper-main'>
        //                     <div className='api-wrapper' title="Click here to copy API">
        //                         <p className='apilist-list api-copy'> https://api.modernbiztech.com/api/v1/app/project/delete/<span className='apilist-span'>*project_id*</span>?apikey=<span className='apilist-span'>{id.user_id}</span> </p>
        //                         <Button
        //                             className='copy-button'
        //                             onClick={() => {
        //                                 setCopy(true)
        //                                 return navigator.clipboard.writeText(`https://api.modernbiztech.com/api/v1/app/project/delete/*project_id*?apikey=${id.user_id}`);
        //                             }} sx={{ marginLeft: '10px', height: '36px', bgcolor: '#222222', color: 'white', ':hover': { bgcolor: '#222222' } }} variant="contained">Copy</Button>
        //                     </div>
        //                 </div>
        //                 {/* <p className='apilist-body'>- Method : GET</p>
        //                 <p className='apilist-body'>- Header : ""</p>
        //                 <p className='apilist-body'>- Body : ""</p>
        //                 <p className='apilist-body'>- Response: &#123;<br /></p>
        //                 <p className='apilist-api'>status : success/fail<br /></p>
        //                 <p className='apilist-api'>message : ""<br /></p> */}
        //                 {/* <p style={{ paddingBottom: '50px' }} className='apilist-body'> &#125;<br /></p> */}
        //                 <p style={{ paddingBottom: '50px' }} className='apilist-body'></p>
        //             </Paper>
        //         </Box>
        //         <Box sx={{ width: '100%', height: '35%', bgcolor: '#222222', justifyContent: 'center' }}>
        //             <footer className="w-100 py-4 flex-shrink-0 footer-wrapper">
        //                 <div className="container py-4 ">
        //                     <div className="row gy-4 gx-5">
        //                         <div className="col-lg-4 col-md-6">
        //                             <a style={{ marginLeft: '30px' }} className="" href='http://demego.vn/page/home.html'><img className="img-fluid" alt="" src={`${BASE_URL}/images/logo-footer.png`}></img></a>
        //                             <p className="small text-muted">TECHNOLOGY & REAL ESTATE AGENCIES</p>
        //                             <p className="small text-muted mb-0">WE ARE DEMEGO. <a className="text-primary" href="http://demego.vn/page/home.html">Demego.vn</a></p>
        //                         </div>
        //                         <div className="col-lg-8 col-md-6">
        //                             <h5 className="text-white mb-3">Contact us:</h5>
        //                             <ul className="list-unstyled text-muted footer-list">
        //                                 <li><MailOutlineOutlinedIcon /> info@demego.vn</li>
        //                                 <li><CallOutlinedIcon /> HOTLINE: 1900 8888</li>
        //                                 <li><LocationOnOutlinedIcon /> 52 Xã Đàn, P.Phương Liệt, Q.Đống Đa, Hà Nội.</li>
        //                             </ul>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </footer>
        //         </Box>
        //     </Box>

        // </>